import { DOCUMENT } from '@angular/common';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { B2CAuthService } from '@literax/b2c-auth/b2c-auth.service';
import { CustomFormValidator } from '@literax/modules/shared/form-lib/custom-form.validator';
import {
  IPaymentConfWidget,
  testValidPlans,
} from '@literax/models/http/onboarding/paymentConf.model';
import { LoadingService } from '@literax/services/loading/loading.service';
import { I18nToastrService } from '@literax/services/translate/i18n-toastr.service';
import { IAppState } from '@literax/store';
import {
  cleanRegistrationState,
  createClient,
  getPlans,
} from '@literax/store/registration/registration.actions';
import {
  selectClientCreation,
  selectPlans,
} from '@literax/store/registration/registration.selectors';
import { AppUtils } from '@literax/utils/app.utils';
import { environment } from '@environments/environment';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, of } from 'rxjs';
import { IPlan, IReferenceResponse } from '@literax/models/plan.model';
import { ICreateClient, IValidateClient } from '@literax/models/client.model';
import { catchError, filter, map } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OnboardingService } from '@literax/services/onboarding/onboarding.service';
import { ClientService } from '@literax/services/clientV3/client.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { PlanService } from '@literax/services/plan/plan.service';
import { passwordMatchingValidatior } from './custom-validators/password-matching-validator.component';
import { IApiDetailedError } from '@literax/models/common';
import { ModalVerifyEmailCodeComponent } from '@literax/modules/shared/modal-verify-email-code/modal-verify-email-code.component';
import { VerifyEmailSuccessModalComponent } from '@literax/modules/shared/verify-email-success-modal/verify-email-success-modal.component';
@UntilDestroy()
@Component({
  selector: 'literax-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  public formEmailConfirmation = new FormGroup(
    {
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
      acceptTerms: new FormControl(false, []),
    },
    { validators: passwordMatchingValidatior }
  );

  public formProfile = new FormGroup(
    {
      taxId:  new FormControl(null, [Validators.required, Validators.maxLength(15)]),
      businessName:  new FormControl(null, [Validators.required]),
      phone: new FormControl(null),
      landlinePhone: new FormControl(null),
      email: new FormControl(''),
      password: new FormControl('', [Validators.required]),
      confirmPassword: new FormControl('', [Validators.required]),
    },
    { validators: passwordMatchingValidatior }
  );

  billingForm = new FormGroup(
    {
      taxId:  new FormControl(null, [Validators.required, Validators.maxLength(15)]),
      businessName:  new FormControl(null, [Validators.required]),
      taxRegime:  new FormControl(null, [Validators.required]),
      cfdi:  new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
      zipCode: new FormControl(null, [Validators.required]),
      address: new FormControl(null, []),
      phone: new FormControl(null, [Validators.required]),
    }
  );


  formBillingData = new FormGroup(
    {
      taxId:  new FormControl(null, []),
      businessName:  new FormControl(null, []),
      taxRegime:  new FormControl(null, [Validators.required]),
      cfdi:  new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
      zipCode: new FormControl(null, [Validators.required]),
      // addressForm: new FormControl(false, []),
      // state: new FormControl(null, []),
      // city: new FormControl(null, []),
      // suburb: new FormControl(null, []),
      // street: new FormControl(null, []),
      // externalNumber: new FormControl(null, []),
      // internalNumber: new FormControl(null, []),
    }
  );

  areTermsAccepted = false;
  showAddressForm = false;

  form: FormGroup = new FormGroup({
    user: new FormGroup({
      name: new FormControl('', [Validators.required]), // ? fisica
      last_name: new FormControl('', [Validators.required]), // ? fisica
      email: new FormControl('', [Validators.required, Validators.email]), // ? empresa / fisica
      rfc: new FormControl('', [
        // ? fisica
        Validators.required,
        CustomFormValidator.naturalPersonRFC,
      ]),
      password: new FormControl('', [
        // ? empresa / fisica
        Validators.required,
        Validators.minLength(8),
      ]),
    }),
    country: new FormControl('', [
      // ? empresa / fisica
      Validators.required,
      Validators.minLength(1),
    ]),
    postal_code: new FormControl('', [
      // ? empresa / fisica
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(5),
      Validators.maxLength(5),
    ]),
    phone: new FormControl('', [
      // ? empresa / fisica
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),

    business_name: new FormControl(''), // ? empresa
    tax_id: new FormControl(''), // ? empresa
    tradename: new FormControl(''), // ? empresa
  });

  countries = [];
  plans: IPlan[];
  selectedCard: number;
  selectedProduct: string;
  defaultCardColor = '#eeeeee';
  selectedCardColor = '#1976d2';
  selectedCardColorPlanHeader = '#424242';
  selectedCardColorPlanDescription = '#212121';
  newTextColor = '#212121';
  newArrowGray = '#757575';
  paymentSuccess: boolean;
  registrationSuccess = false;
  isLegalPerson = false;
  isTrial = false;
  emailPattern = AppUtils.emailPattern;
  isLoggedIn = false;
  b2cToken$: BehaviorSubject<string> = new BehaviorSubject('');
  domiciledName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataFrom365Login: any;
  // @ViewChild('registrationForm') formElement: ElementRef<HTMLFormElement>;
  // @ViewChild('paymentWidget', {static: true}) paymentWidget: ElementRef<HTMLDivElement>;
  @ViewChild('paymentWidget', { static: false })
  paymentWidget: ElementRef<HTMLDivElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paymentWidgetTokenResponse: any;
  currentStep = 0;

  createClientRequest: ICreateClient = {
    countryName: '',
    phone: '',
    postalCode: '',
    taxId: '',
    naturalPerson: {
      name: '',
      lastName: '',
    },
    legalPerson: {
      businessName: '',
      tradeName: '',
    },
    user: {
      email: '',
      password: '',
    },
    productId: '',
    reference: '',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: I18nToastrService,
    private store: Store<IAppState>,
    private loading: LoadingService,
    private renderer2: Renderer2,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private b2cAuthService: B2CAuthService,
    @Inject(DOCUMENT) private _document: Document,
    private changeDetectorRef: ChangeDetectorRef,
    private onBoardingService: OnboardingService,
    private clientService: ClientService,
    private modalService: NzModalService,
    private planService: PlanService
  ) {}

  updateFieldTerms() {
    this.areTermsAccepted = this.formEmailConfirmation.get('acceptTerms')
      .value as boolean;
    console.log('test');
    console.log(this.areTermsAccepted);
  }

  toggleShowAddressForm(){
    this.showAddressForm = this.formBillingData.get('addressForm')
      .value as boolean;
  }

  ngOnInit() {
    this.translate
      .get('COUNTRIES')
      .pipe(untilDestroyed(this))
      .subscribe((countriesTranslated) => {
        if (Array.isArray(countriesTranslated)) {
          this.countries = countriesTranslated.map((country: string) => ({
            value: country,
            label: country,
          }));
        }
        // ! FIXME: Enviar como valor predeterminado 'México'
        this.form.get('country').setValue(this.countries[116]);
        this.formBillingData.get('country').setValue(this.countries[116].value);
      });

    this.loadPaymentWidgetScript();

    if (this.router.url.includes('active')) {
      this.isLoggedIn = this.b2cAuthService.isLoggedIn();
    }

    if (this.isLoggedIn) {
      this.cleanUserValidation();
      this.b2cAuthService
        .getToken()
        .pipe(untilDestroyed(this))
        .subscribe((token) => {
          this.b2cToken$.next(token);
        });
    }

    if (
      this.route.snapshot.queryParams.hasOwnProperty(
        environment.onboarding365JoinQueryKey
      )
    ) {
      this.dataFrom365Login = JSON.parse(
        atob(
          this.route.snapshot.queryParams[environment.onboarding365JoinQueryKey]
        )
      );

      this.form.get('user').get('email').setValue(this.dataFrom365Login?.email);
      this.form
        .get('user')
        .get('name')
        .setValue(this.dataFrom365Login?.firstname);
      this.form
        .get('user')
        .get('last_name')
        .setValue(this.dataFrom365Login?.lastname);
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.store.dispatch(cleanRegistrationState());
  }

  loadPaymentWidgetScript() {
    if (!this._document.querySelector('#payment-widget-script')) {
      const cache = new Date().toISOString().split('T')[0].replace(/-/g, ' ');

      // Add widget stylesheet
      const link = this.renderer2.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = `${environment.paymentWidgetGetnetConfig.url}/css/banks-widget.css?v=${cache}`;
      this.renderer2.appendChild(this._document.head, link);

      // Add widget script
      const script = this.renderer2.createElement('script');
      script.id = 'payment-widget-script';
      script.type = 'text/javascript';
      script.src = `${environment.paymentWidgetGetnetConfig.url}/js/banks-widget.js?v=${cache}`;
      script.text = '';
      this.renderer2.appendChild(this._document.body, script);
    }
  }

  cleanUserValidation() {
    const cleanControls: string[] = ['email', 'password'];
    for (const key in this.form.controls.user['controls']) {
      if (cleanControls.indexOf(key) > -1) {
        this.form.get('user').get(key).setErrors(null);
        this.form.get('user').get(key).clearValidators();
        this.form.get('user').get(key).updateValueAndValidity();
      }
    }
  }

  togglePersonType(isLegal: boolean) {
    if (isLegal) {
      this.form.get('user').get('rfc').setErrors(null);
      this.form.get('user').get('rfc').clearValidators();
      this.form.get('user').get('rfc').updateValueAndValidity();
      this.form.get('user').get('name').setErrors(null);
      this.form.get('user').get('name').clearValidators();
      this.form.get('user').get('name').updateValueAndValidity();
      this.form.get('user').get('last_name').setErrors(null);
      this.form.get('user').get('last_name').clearValidators();
      this.form.get('user').get('last_name').updateValueAndValidity();
      this.form
        .get('tax_id')
        .setValidators([
          Validators.required,
          CustomFormValidator.legalPersonRFC,
        ]);
      this.form.get('tax_id').updateValueAndValidity();
      this.form.get('tradename').setValidators([Validators.required]);
      this.form.get('tradename').updateValueAndValidity();
      this.form.get('business_name').setValidators([Validators.required]);
      this.form.get('business_name').updateValueAndValidity();
    } else {
      this.form.get('tax_id').setErrors(null);
      this.form.get('tax_id').clearValidators();
      this.form.get('tax_id').updateValueAndValidity();
      this.form.get('tradename').setErrors(null);
      this.form.get('tradename').clearValidators();
      this.form.get('tradename').updateValueAndValidity();
      this.form.get('business_name').setErrors(null);
      this.form.get('business_name').clearValidators();
      this.form.get('business_name').updateValueAndValidity();
      this.form
        .get('user')
        .get('rfc')
        .setValidators([
          Validators.required,
          CustomFormValidator.naturalPersonRFC,
        ]);
      this.form.get('user').get('rfc').updateValueAndValidity();
      this.form.get('user').get('name').setValidators([Validators.required]);
      this.form.get('user').get('name').updateValueAndValidity();
      this.form
        .get('user')
        .get('last_name')
        .setValidators([Validators.required]);
      this.form.get('user').get('last_name').updateValueAndValidity();
    }

    this.isLegalPerson = isLegal;
  }

  //#region First step
  /**
   * The function validates the client info, if the form is valid, it dispatches an action to validate
   * the client, then it subscribes to the store to get the validation result, if the result is true,
   * it navigates to the next step and dispatches an action to get the plans, then it subscribes to the
   * store to get the plans
   */
  validateClientInfo() {
    if (this.form.valid) {
      this.clientService
        .validateClient(this.buildValidateClientRequest())
        .subscribe(
          () => {
            this.next();
            this.store.dispatch(getPlans());
            this.store
              .pipe(
                untilDestroyed(this),
                select(selectPlans),
                filter((plans) => !!plans)
              )
              .subscribe((plans: IPlan[]) => {
                if (environment.production) {
                  this.plans = plans;
                } else {
                  this.plans = plans.filter((x) =>
                    testValidPlans.includes(x.productId)
                  );
                }
              });
          },
          (error) => {
            this.modalService.error({
              nzTitle: 'Error!',
              nzContent: error.message,
            });
          }
        );
    }
  }

  /**
   * It builds an object with the email and taxId of the client
   */
  private buildValidateClientRequest(): IValidateClient {
    const dataClient: IValidateClient = {
      email: '',
      taxId: '',
    };
    if (this.isLegalPerson) {
      this.form
        .get('user')
        .get('name')
        .setValue(this.form.controls.business_name.value);
      this.form
        .get('user')
        .get('last_name')
        .setValue(this.form.controls.tradename.value);
    } else {
      this.form.get('tax_id').setValue(this.form.get('user').get('rfc').value);
    }
    dataClient.email = this.form.get('user').get('email').value;
    dataClient.taxId = this.form.controls.tax_id.value;
    return dataClient;
  }
  //#endregion First step

  //#region Second step
  onCardClicked(index: number, productId: string, isTrial) {
    this.selectedCard = index;
    this.selectedProduct = productId;
    this.isTrial = isTrial;
  }

  onPaymentClicked() {
    this.loading.show();
    this.createReferenceRequest();
    const refRequest = {
      taxId: this.createClientRequest.taxId,
      businessName: this.isLegalPerson
        ? this.createClientRequest.legalPerson.businessName
        : this.createClientRequest.naturalPerson.name +
          ' ' +
          this.createClientRequest.naturalPerson.lastName,
      productUnit: this.plans.find(
        (plan) => plan.productId === this.selectedProduct
      ).unit,
    };

    this.planService.getReference(this.selectedProduct, refRequest).subscribe(
      async (planReference: IReferenceResponse) => {
        if (this.isTrial) {
          this.loading.hide();
          this.next();
          this.onRegistrationClicked();
        } else {
          const infoPlan = this.plans.find(
            (plan) => plan.productId === this.selectedProduct
          );
          this.paymentWidgetTokenResponse =
            await this.onBoardingService.getB2cTokenSynchronous();
          const confPaymentDataWidget: IPaymentConfWidget = {
            mode: 'getnet-payment',
            data: {
              clientId: environment.paymentCustomerId,
              externalClientId: planReference.taxId,
              reference: planReference.reference,
              currency: 'MXN',
              amount: planReference.amount.toString(),
              recurring: true,
              recurringData: {
                name: this.isLegalPerson
                  ? this.createClientRequest.legalPerson.businessName
                  : this.createClientRequest.naturalPerson.name +
                    ' ' +
                    this.createClientRequest.naturalPerson.lastName,
                conceptId: infoPlan.sku,
                conceptName: infoPlan.name,
              },
              provider: 'GETNET',
            },
            access_token: this.paymentWidgetTokenResponse.access_token,
            refresh_token: this.paymentWidgetTokenResponse.refresh_token,
          };
          this.next();
          this.changeDetectorRef.detectChanges();
          this.setPaymentData(confPaymentDataWidget);
          this.loadPaymentWidget();
        }
      },
      (error) => {
        console.log(error);
        this.modalService.error({
          nzTitle: 'Error!',
          nzContent:
            error.message === null || error.message === ''
              ? 'Ha ocurrido un error, por favor inténtelo de nuevo'
              : error.message,
        });
        this.loading.hide();
      }
    );
  }

  createReferenceRequest() {
    if (this.isLegalPerson) {
      this.createClientRequest.legalPerson.businessName =
        this.form.get('business_name').value;
      this.createClientRequest.legalPerson.tradeName =
        this.form.get('tradename').value;
    } else {
      this.createClientRequest.naturalPerson.name = this.form
        .get('user')
        .get('name').value;
      this.createClientRequest.naturalPerson.lastName = this.form
        .get('user')
        .get('last_name').value;
    }
    this.createClientRequest.countryName = this.form.get('country').value.value;
    this.createClientRequest.phone = this.form.get('phone').value;
    this.createClientRequest.postalCode = this.form.get('postal_code').value;
    this.createClientRequest.taxId = this.form.get('tax_id').value;
    this.createClientRequest.user.email = this.form
      .get('user')
      .get('email').value;
    this.createClientRequest.user.password = this.form
      .get('user')
      .get('password').value;
    this.createClientRequest.productId = this.selectedProduct;
  }
  //#endregion Second step
  setPaymentData(confPaymentData: IPaymentConfWidget) {
    this.paymentWidget.nativeElement.innerHTML =
      '<banks-widget params=' +
      encodeURIComponent(JSON.stringify(confPaymentData)) +
      '></banks-widget>';
  }

  loadPaymentWidget() {
    if (this.paymentWidget.nativeElement.querySelector('banks-widget')) {
      this.paymentWidget.nativeElement
        .querySelector('banks-widget')
        .addEventListener('paymentNotification', (e) => {
          console.log('evento emitido: ', e);
          this.paymentSuccess = true;
          this.toastr.success(
            'TRANSACTIONS.PAYMENT_WIDGET.SUCCESS_MSG',
            'TRANSACTIONS.PAYMENT_WIDGET.SUCCESS_TITLE'
          );
          setTimeout(() => {
            this.loading.hide();
            this.onRedirectTime();
          }, 600);
        });
      this.loading.hide();
    }
  }

  onRedirectTime() {
    if (this.paymentSuccess) {
      this.onRegistrationClicked();
    }
  }

  onRegistrationClicked() {
    // if (this.isLegalPerson) {
    //   delete this.createClientRequest.naturalPerson;
    // } else {
    //   delete this.createClientRequest.legalPerson;
    // }
    // this.store.dispatch(
    //   createClient({
    //     dataClient: this.createClientRequest,
    //   })
    // );
    // this.store
    //   .pipe(
    //     untilDestroyed(this),
    //     select(selectClientCreation),
    //     filter((clientCreation) => clientCreation !== null)
    //   )
    //   .subscribe((clientCreation: boolean) => {
    //     this.registrationSuccess = clientCreation;
    //     if (this.registrationSuccess) {
    //       // TODO: Averiguar si si se debe ocupar o no
    //       // this.msalService.logout();
    //     }
    //     this.next();
    //   });
    console.log('🚀 ~ onRegistrationClicked ~ this.createClientRequest', this.createClientRequest);
    this.next();
  }
  //#endregion Third step

  onCancelClicked() {
    this.router.navigate(['auth/b2c-login']);
  }

  goToInterfirma(): void {
    window.location.href = environment.literaxUrl;
  }

  pre(): void {
    this.currentStep -= 1;
  }

  next(): void {
    this.currentStep += 1;
  }

  sendVerificationCode() {
    // const emailToVerify = this.formEmailConfirmation.get('email').value;

    // this.clientService
    //   .requestVerificationCode({
    //     email: emailToVerify,
    //     fullName: emailToVerify,
    //   })
    //   .subscribe(
    //     () => {
    //       this.modalService.create({
    //         nzClosable: false,
    //         nzContent: ModalVerifyEmailCodeComponent,
    //       })
    //     },
    //     (error) => {
    //       this.modalService.error({
    //         nzTitle: 'Error!',
    //         nzContent: error.message,
    //       });
    //     }
    //   );

    this.modalService.create({
      nzMaskClosable: false,
      nzClosable: false,
      nzTitle: this.translate.instant('MODULES.WORKSPACE.MODALS.TITLES.EMAIL_VERIFICATION'),
      nzContent: ModalVerifyEmailCodeComponent,
      nzBodyStyle: {
        padding: '16px',
      },
      nzWidth: 600
    }).afterClose.pipe(
      untilDestroyed(this)
    ).subscribe((isVerificationCodeCorrect: boolean) => {
      if (isVerificationCodeCorrect) {
        this.modalService.create({
          nzMaskClosable: false,
          nzClosable: false,
          nzContent: VerifyEmailSuccessModalComponent,
          nzWidth: 600,
          nzFooter: null
        }).afterClose.pipe(
          untilDestroyed(this)
        ).subscribe( _ => this.next());
      }
    });
  }

  goToBilling(membershipType, i) {
    console.log("🚀 ~ goToBilling ~ membershipType:", membershipType);
    console.log("🚀 ~ goToBilling ~ i:", i);
    //TODO: consultar catalogos de cfdi, regimen fiscal, pais
    this.next();
  }
  goToPayment() {
    this.next();
  }

  openLinkNewTab(link: string) {
    return () => {
      window.open(link, '_blank');
    };
  }
}
