<nz-layout>
  <literax-auth-navbar
    [title]="'AUTH.TITLE.NAVBAR' | translate"
    [standalone]="true"
  ></literax-auth-navbar>
  <nz-content >
    <div class="container-fluid vh-100 no-gutters p-0">
      <div class="row vh-100 no-gutters">
        <div class="col-md-6 d-flex align-items-center justify-content-center">
          <div class="text-center main-content" *ngIf="!isEmailSent">
            <h1>
              {{
                'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.TITLE' | translate
              }}
            </h1>
            <h5 class="subtitle">
              {{
                'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.SUBTITLE'
                  | translate
              }}
            </h5>
            <form [formGroup]="formEmailValidation" class="pb-3">
              <div nz-row>
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                  <literax-form-field
                    [required]="true"
                    [label]="
                      'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.FORM.EMAIL'
                        | translate
                    "
                    formControlName="email"
                    [placeholder]="
                      'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.FORM.EMAIL_PLACEHOLDER'
                        | translate
                    "
                  >
                  </literax-form-field>
                </div>
              </div>
            </form>
            <p>
              {{
                'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.TERMS_CONDITIONS_1'
                  | translate
              }}
              <br />
              <a
                href="https://www.literax.com/wp-content/uploads/2021/06/aviso_de_privacidad.pdf"
                target="_blank"
                >{{
                  'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.TERMS_CONDITIONS_2'
                    | translate
                }}</a
              >
              {{
                'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.TERMS_CONDITIONS_3'
                  | translate
              }}
              <a
                href="https://www.literax.com/wp-content/uploads/2021/06/Terminos-y-condiciones.pdf"
                target="_blank"
                >{{
                  'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.TERMS_CONDITIONS_4'
                    | translate
                }}</a
              >
            </p>
            <button nz-button nzType="primary" (click)="validateClientEmail()">
              {{
                'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.FORM.BTN_CONTINUE'
                  | translate
              }}
            </button>
          </div>
          <div class="text-center main-content_alt" *ngIf="isEmailSent">
            <h1>
              {{
                'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.EMAIL_SENT.TITLE'
                  | translate
              }}
            </h1>
            <h5 class="subtitle pb-3">
              {{
                'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.EMAIL_SENT.SUBTITLE'
                  | translate
              }}
              <br />
              <span>{{ email }}</span>
            </h5>
            <p>
              <a [routerLink]="" (click)="validateClientEmail()">{{
                'CONFIGURATIONS_MODULE.USERS.EMAIL_VALIDATION.EMAIL_SENT.RESEND_EMAIL_TITLE'
                  | translate
              }}</a>
            </p>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-block bg-img">
          <div class="bg-cover"></div>
        </div>
      </div>
    </div>
  </nz-content>
</nz-layout>
