import { ControlValueAccessor, NgControl } from '@angular/forms';
import {
  Component,
  Input,
  Self,
  Optional,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';

import { LITERAX_DEFAULT_ERRORS } from './model/error.model';
import { TranslateService } from '@ngx-translate/core';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
@UntilDestroy()
@Component({
  selector: 'literax-form-field',
  templateUrl: './literax-form-field.component.html',
  styleUrls: ['./literax-form-field.component.scss'],
})
export class LiteraxFormFieldComponent
  implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() label = '';
  @Input() size = 'default';
  @Input() type = 'text';
  @Input() placeholder = '';
  @Input() max: number;
  @Input() min: number;
  @Input() maxlength: string;
  @Input() minlength: string;
  @Input() mask: string;
  @Input() pattern: string;
  @Input() upperCase = false;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() dateFormat: string;
  @Input() options: Array<any>;
  @Input() colorsHex = false;
  @Input() selectMode = 'default';
  @Input() hint: string = null;

  _daysDisabled: any;
  @Input() set dateDisabled(date) {
    this._daysDisabled = date;
  }
  errorsArray: string[];
  firstError: string = null;
  changed = false;
  @Input() set apiError(apiError: string[]) {
    this.changed = false;
    this.errorsArray = Array.isArray(apiError) ? apiError : null;
    this.firstError = Array.isArray(apiError) ? apiError[0] : null;
  }
  value: any;

  passwordVisible = false;

  private errorMessages = new Map<string, () => string>();

  public onChangeFn = (_: any) => { };
  public onTouchedFn = () => { };

  constructor(
    @Self() @Optional() public control: NgControl,
    private translateService: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    this.control && (this.control.valueAccessor = this);
    this.errorMessages.set('required', () => LITERAX_DEFAULT_ERRORS.required);
    this.errorMessages.set(
      'incorrectPassword',
      () => LITERAX_DEFAULT_ERRORS.password
    );
    this.errorMessages.set('email', () => LITERAX_DEFAULT_ERRORS.email);
    this.errorMessages.set('min', () => LITERAX_DEFAULT_ERRORS.min);
    this.errorMessages.set('max', () => LITERAX_DEFAULT_ERRORS.max);
    this.errorMessages.set('minlength', () => LITERAX_DEFAULT_ERRORS.minlength);
    this.errorMessages.set('maxlength', () => LITERAX_DEFAULT_ERRORS.maxlength);
    this.errorMessages.set('pattern', () => LITERAX_DEFAULT_ERRORS.pattern);
    this.errorMessages.set('rfc', () => LITERAX_DEFAULT_ERRORS.rfc);
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(untilDestroyed(this), debounceTime(300), distinctUntilChanged())
      .subscribe(() => (this.changed = true));
  }

  ngOnDestroy(): void { }

  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  public get showError(): boolean {
    if (!this.control) {
      return false;
    }
    const { dirty, touched } = this.control;
    return this.invalid ? dirty || touched : false;
  }

  public get errors(): Array<string> {
    if (!this.control) {
      return [];
    }
    const { errors } = this.control;
    if (errors.maxlength) {
      this.maxlength = errors.maxlength.requiredLength;
    } else if (errors.minlength) {
      this.minlength = errors.minlength.requiredLength;
    } else if (errors.max) {
      this.max = errors.max;
    } else if (errors.min) {
      this.min = errors.min;
    }
    return Object.keys(errors)
      .map((key) =>
        this.errorMessages.has(key)
          ? this.errorMessages.get(key)()
          : <string>errors[key] || key
      )
      .filter((error) => typeof error !== 'boolean');
  }

  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public onChange() {
    if (this.upperCase) {
      this.value = this.value.toUpperCase();
    }
    this.onChangeFn(this.value);
  }
}
