<div class="wrapper">
  <nz-form-label *ngIf="label" nzRequired="{{ required }}" nzNoColon="true">
    <nz-space>
      <ng-container *nzSpaceItem>
        {{ label }}
      </ng-container>
      <ng-container *nzSpaceItem>
        <span nz-icon *ngIf="hint" [ngbTooltip]="hint" container="body" class="hint">
          <i class="fal fa-info-circle"></i>
        </span>
      </ng-container>
    </nz-space>
  </nz-form-label>
  <nz-date-picker [(ngModel)]="value" [ngModelOptions]="{ standalone: true }" [nzFormat]="dateFormat"
    [nzDisabledDate]="_daysDisabled" *ngIf="type === 'calendar'" (ngModelChange)="onChange()">
  </nz-date-picker>

  <input *ngIf="type === 'text'" nz-input [nzSize]="size" [type]="type"
    [ngClass]="{ 'literax-form-field-invalid': showError }" [placeholder]="placeholder" [max]="max" [min]="min"
    [maxlength]="maxlength" [minlength]="minlength" [mask]="mask" [pattern]="pattern" [readonly]="readonly"
    [disabled]="disabled" [(ngModel)]="value" [ngModelOptions]="{ standalone: true }" (input)="onChange()"
    (change)="onChange()" (blur)="onTouchedFn()" [ColorsHex]="colorsHex" />

  <nz-input-group *ngIf="type === 'password'"  [nzSuffix]="suffixTemplate">
    <input nz-input [nzSize]="size" [type]="passwordVisible ? 'text' : type"
      [ngClass]="{ 'literax-form-field-invalid': showError }" [placeholder]="placeholder" [max]="max" [min]="min"
      [maxlength]="maxlength" [minlength]="minlength" [mask]="mask" [pattern]="pattern" [readonly]="readonly"
      [disabled]="disabled" [(ngModel)]="value" [ngModelOptions]="{ standalone: true }" (input)="onChange()"
      (change)="onChange()" (blur)="onTouchedFn()" [ColorsHex]="colorsHex" />
  </nz-input-group>
  <ng-template #suffixTemplate>
    <span
      nz-icon
      [nzType]="passwordVisible ? 'eye-invisible' : 'eye'"
      (click)="passwordVisible = !passwordVisible"
    ></span>
  </ng-template>

  <nz-select *ngIf="type === 'select'" [ngClass]="{ 'literax-form-field-invalid': showError }" [nzSize]="size"
    [nzPlaceHolder]="placeholder" [(ngModel)]="value" [nzMode]="selectMode" (ngModelChange)="onChange()">
    <nz-option *ngFor="let option of options" [nzLabel]="option.label" [nzValue]="option.value">
    </nz-option>
  </nz-select>
  <span class="literax-form-field-error" *ngIf="showError && errors.length">
    <i class="fa fa-times-circle"></i>
    {{
    errors[0]
    | translate
    : {
    field: label,
    minlength: minlength,
    maxlength: maxlength,
    max: max,
    min: min
    }
    }}
  </span>
  <span class="literax-form-field-error" *ngIf="!control.invalid && firstError !== null && !changed">
    <i class="fa fa-times-circle"> </i>
    {{ firstError }}
  </span>
</div>
