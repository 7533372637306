import { Component } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'literax-verify-email-success-modal',
  templateUrl: './verify-email-success-modal.component.html',
  styleUrls: ['./verify-email-success-modal.component.scss']
})
export class VerifyEmailSuccessModalComponent {
  
  constructor (
    private modal: NzModalService
  ) { }

  clickOk() {
    this.modal.closeAll();
  }
}
