import * as AllIcons from '@ant-design/icons-angular/icons';

import {
  AssignmentDialogComponent,
  AssignmentModalSuccessComponent,
} from './assignment-dialog/assignment-dialog.component';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NZ_I18N, es_ES } from 'ng-zorro-antd/i18n';
import {
  NgbDatepickerModule,
  NgbPopoverModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NzTableModule, NzTableStyleService } from 'ng-zorro-antd/table';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AcceptModalComponent } from './accept-modal/accept-modal.component';
import { ActionModalComponent } from './confirm-modal/confirm-modal.component';
import { ApiErrorComponent } from './api-error/api-error.component';
import { ApplicantComponent } from '@literax/components/platform/workspace/applicants/applicant/applicant.component';
import { ApplicantsListContainerComponent } from '@literax/components/platform/workspace/applicants/applicants-list-container/applicants-list-container.component';
import { ApprovalButtonsComponent } from '../documents/workspace/flows/signature/components/buttons-bar/approval-buttons/approval-buttons.component';
import { ApproverComponent } from '@literax/components/platform/workspace/approvers/approver/approver.component';
import { ApproversListContainerComponent } from '@literax/components/platform/workspace/approvers/approvers-list-container/approvers-list-container.component';
import { AttachmentComponent } from '@literax/components/platform/workspace/attachments/attachment/attachment.component';
import { AttachmentsComponent } from '@literax/components/platform/workspace/attachments/attachments.component';
import { AuthNavbarComponent } from './auth-navbar/auth-navbar.component';
import { ButtonComponent } from './button/button.component';
import { ButtonsBarLayoutComponent } from '../documents/workspace/components/buttons-bar-layout/buttons-bar-layout.component';
import { ButtonsBarV2Component } from '@literax/components/platform/workspace/buttons-bar-v2/buttons-bar-v2.component';
import { CalendarActiviyComponent } from './calendar-activiy/calendar-activiy.component';
import { ChartsModule } from 'ng2-charts';
import { CircleComponent } from './circle/circle.component';
import { ClickStopPropagationDirective } from '@literax/modules/shared/directives/click-stop-propagation.directive';
import { ColorsHex } from '../../directives/colorsHex.directive';
import { CommentsComponent } from '@literax/components/platform/workspace/comments/comments.component';
import { CommentsSectionComponent } from '@literax/components/platform/document-preview/comments-section/comments-section.component';
import { CommonModule } from '@angular/common';
import { ComposeAttachmentFileComponent } from './compose-attachment-file/compose-attachment-file.component';
import { ConfirmDialogApprovalComponent } from './confirm-dialog-approval/confirm-dialog-approval.component';
import { ConfirmDialogDelegateComponent } from './confirm-dialog-delegate/confirm-dialog-delegate.component';
import { ConfirmationTycModalComponent } from './confirmation-tyc-modal/confirmation-tyc-modal.component';
import { ControlErrorComponent } from './control-error/control-error.component';
import { ControlErrorContainerDirective } from './form-lib/directives/control-error-container.directive';
import { ControlErrorsDirective } from './form-lib/directives/control-errors.directive';
import { DateDropdownComponent } from './date-dropdown/date-dropdown.component';
import { DebounceClickDirective } from './form-lib/directives/debounce-click.directive';
import { DelegateProcessDialogComponent } from './delegate-process-dialog/delegate-process-dialog.component';
import { DigitalSignatureFormComponent } from './digital-signature-form/digital-signature-form.component';
import { DocumentAttachmentsContainerComponent } from './document-attachments-container/document-attachments-container.component';
import { DocumentDetailInformationComponent } from './document-detail-information/document-detail-information.component';
import { DocumentTabComponent } from './document-tab/document-tab.component';
import { DocumentTitleComponent } from './document-title/document-title.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DrawerComponent } from './drawer/drawer.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DynamicPipe } from '@literax/pipes/dynamic.pipe';
import { DynamicTabsDirective } from './tabs/dynamic-tabs.directive';
import { E404Component } from './e404/e404.component';
import { E404ValidateTokenComponent } from './e404-validate-token/e404-validate-token.component';
import { EffectsModule } from '@ngrx/effects';
import { ExpiredComponent } from '@literax/components/sign/expired/expired.component';
import { ExpiredLinkComponent } from './expired-link/expired-link.component';
import { FileDndDirective } from './form-lib/directives/file-dnd.directive';
import { FileInputComponent } from './file-input/file-input.component';
import { FormSubmitDirective } from './form-lib/directives/form-submit.directive';
import { FreeDocumentEffects } from '@literax/store/free-document/free-document.effects';
import { GdxFormComponentsModule } from 'gdx-form-components';
import { HighlightModule } from 'ngx-highlightjs';
import { HistoryComponent } from '@literax/components/platform/workspace/history/history.component';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '@literax/services/translate/http-loader.factory';
import { IFPDFComponent } from './if-pdf/if-pdf.component';
import { IconDefinition } from '@ant-design/icons-angular';
import { IdentityValidationFormComponent } from './identity-validation-form/identity-validation-form.component';
import { IdentityValidationModalComponent } from './identity-validation-modal/identity-validation-modal.component';
import { LegalGridComponent } from './legal-grid/legal-grid.component';
import { LegalGridV2Component } from './legal-grid-v2/legal-grid-v2.component';
import { LegalRepresentativesDialogComponent } from './legal-representatives-dialog/legal-representatives-dialog.component';
import { LenguageComponent } from './lenguage/lenguage.component';
import { LiteraxFormFieldComponent } from './literax-form-field/literax-form-field.component';
import { LiteraxStepComponent } from './literax-stepper/literax-step/literax-step.component';
import { LiteraxStepperComponent } from './literax-stepper/literax-stepper.component';
import { LiteraxStepperNextDirective } from './literax-stepper/directives/literax-stepper-next.directive';
import { LiteraxStepperPreviousDirective } from './literax-stepper/directives/literax-stepper-previous.directive';
import { LoadingComponent } from './loading/loading.component';
import { LogoComponent } from './logo/logo.component';
import { MarkdownModule } from 'ngx-markdown';
import { MenuButtonDocumentComponent } from './menu-button-document/menu-button-document.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { ModalAttachmentComponent } from './compose-attachment-file/components/modal-attachment/modal-attachment.component';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { NameInitials } from '@literax/pipes/name-initials.pipe';
import { NavbarComponent } from './navbar/navbar.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NotFoundComponent } from './not-found/not-found.component';
import { NotHiredComponent } from './not-hired/not-hired.component';
import { NzAffixModule } from 'ng-zorro-antd/affix';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { OnDragDirective } from './form-lib/directives/on-drag.directive';
import { OnDragEndDirective } from './form-lib/directives/on-drag-end.directive';
import { OnDragEnterDirective } from './form-lib/directives/on-drag-enter.directive';
import { OnDragLeaveDirective } from './form-lib/directives/on-drag-leave.directive';
import { OnDragOverDirective } from './form-lib/directives/on-drag-over.directive';
import { OnDragStartDirective } from './form-lib/directives/on-drag-start.directive';
import { OnDropDirective } from './form-lib/directives/on-drop.directive';
import { OnlyofficeEditorComponent } from './onlyoffice-editor/onlyoffice-editor.component';
import { PopoverComponent } from './popover/popover.component';
import { PrettifyXML } from '@literax/pipes/prettify-xml.pipe';
import { ProcessTabComponent } from './process-tab/process-tab.component';
import { ProcessesTabBarComponent } from './processes-tab-bar/processes-tab-bar.component';
import { ProfileImageComponent } from './profile-image/profile-image.component';
import { RegisterPaymentComponent } from './register-payment/register-payment.component';
import { RegisterPaymentContainerComponent } from './register-payment-container/register-payment-container.component';
import { RegistrationSuccessModalComponent } from './registration-success-modal/registration-success-modal.component';
import { RejectedComponent } from './rejected/rejected.component';
import { RequestNewLinkDialogComponent } from './request-new-link-dialog/request-new-link-dialog.component';
import { ReviewButtonsComponent } from '../documents/workspace/flows/signature/components/buttons-bar/review-buttons/review-buttons.component';
import { ReviewedComponent } from './reviewed/reviewed.component';
import { ReviewerComponent } from '@literax/components/platform/workspace/reviewers/reviewer/reviewer.component';
import { ReviewersListContainerComponent } from '@literax/components/platform/workspace/reviewers/reviewers-list-container/reviewers-list-container.component';
import { RouterModule } from '@angular/router';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SectionToggleComponent } from './section-toggle/section-toggle.component';
import { SignDocumentComponent } from './sign-document/sign-document.component';
import { SignModalComponent } from '@literax/components/platform/sign-modal/sign-modal.component';
import { SignatureButtonsComponent } from '../documents/workspace/flows/signature/components/buttons-bar/signature-buttons/signature-buttons.component';
import { SignatureCanvasComponent } from './signature-canvas/signature-canvas.component';
import { SignatureFormComponent } from './signature-form/signature-form.component';
import { SignedComponent } from './signed/signed.component';
import { SignedListContainerComponent } from './signed-list-container/signed-list-container.component';
import { SignerInfoComponent } from '@literax/components/platform/document-preview/signer-info/signer-info.component';
import { SignersComponent } from '@literax/components/platform/workspace/signers/signers.component';
import { SortByPipe } from '@literax/pipes/sort-by.pipe';
import { TabComponent } from './tabs/tab/tab.component';
import { TabbedContainerComponent } from './tabbed-container/tabbed-container.component';
import { TableComponent } from './table/table.component';
import { TabsComponent } from './tabs/tabs.component';
import { TaskComponent } from './calendar-activiy/task/task.component';
import { TitleNavbarComponent } from './title-navbar/title-navbar.component';
import { ToValidateComponent } from './to-validate/to-validate.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { TranslateMomentDatePipe } from '@literax/pipes/translate-moment-date.pipe';
import { TycModalComponent } from './tyc-modal/tyc-modal.component';
import { UnixtimestampToDatePipe } from '@literax/pipes/unixtimestamp-to-date.pipe';
import { UploadButtonControlComponent } from './upload-button-control/upload-button-control.component';
import { ValidatePermissionDirective } from '@literax/modules/shared/directives/validate-permission.directive';
import { ValidateRfcComponent } from './validate-rfc/validate-rfc.component';
import { ValidateSignatureTypeDirective } from '@literax/directives/validateSignatureType.directive';
import { ValueFromColumn } from '@literax/pipes/value-from-column.pipe';
import { ViewImageModalComponent } from './view-image-modal/view-image-modal.component';
import { ViewerComponent } from '@literax/components/platform/workspace/viewer/viewer.component';
import { VirtualScrollComponent } from './virtual-scroll/virtual-scroll.component';
import { WorkspaceComponent } from '@literax/components/platform/workspace/workspace.component';
import { WorkspaceLayoutComponent } from '../documents/workspace/components/workspace-layout/workspace-layout.component';
import { environment } from '@environments/environment';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { YopsilonMaskModule } from 'yopsilon-mask';
import { BackButtonComponent } from './back-button/back-button.component';
import { ModalSendSignedDocComponent } from './modal-send-signed-doc/modal-send-signed-doc.component';
import { ModalVerifyEmailCodeComponent } from './modal-verify-email-code/modal-verify-email-code.component';
import { VerifyEmailSuccessModalComponent } from './verify-email-success-modal/verify-email-success-modal.component';
import { NzTypographyModule } from 'ng-zorro-antd/typography';

registerLocaleData(es);

const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(
  (key) => antDesignIcons[key]
);

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  ChartsModule,
  RouterModule,
  ScrollingModule,
  MarkdownModule.forChild(),
  NgSelectModule,
  NgbDatepickerModule,
  NgbTooltipModule,
  NgbPopoverModule,
  YopsilonMaskModule,
  GdxFormComponentsModule.forChild({
    apiUrl: environment.recordUrl,
    font: "'Roboto'",
    urlParams: {
      collection: 'oVpcUgPA9LZ7wM6cN30q5kEw_TOZHEf1SPh14TWFhKw',
    },
  }),
  TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
    isolate: false,
  }),
  DragDropModule,
  NzEmptyModule,
  NzLayoutModule,
  NzIconModule,
  NzMenuModule,
  NzTableModule,
  NzCardModule,
  NzDropDownModule,
  NzTabsModule,
  NzButtonModule,
  NzBadgeModule,
  NzGridModule,
  NzDescriptionsModule,
  NzCollapseModule,
  NzAvatarModule,
  NzAutocompleteModule,
  NzAlertModule,
  NzSelectModule,
  NzInputModule,
  NzDatePickerModule,
  NzUploadModule,
  NzToolTipModule,
  NzRadioModule,
  NzDrawerModule,
  NzAffixModule,
  NzBreadCrumbModule,
  NzPageHeaderModule,
  NzCheckboxModule,
  NzSpaceModule,
  NzDividerModule,
  NzPaginationModule,
  NzTimelineModule,
  NzPopoverModule,
  NzNotificationModule,
  NzCheckboxModule,
  NzModalModule,
  NzFormModule,
  NzSpaceModule,
  NzStepsModule,
  NzSpinModule,
  NzModalModule,
  NzCheckboxModule,
  NzModalModule,
  NzSwitchModule,
  NzResultModule,
  NzTagModule,
  NzSkeletonModule,
  HighlightModule,
  NzCalendarModule,
  NzTypographyModule
];

const components = [
  ExpiredComponent,
  ReviewButtonsComponent,
  ApprovalButtonsComponent,
  ButtonsBarLayoutComponent,
  SignatureButtonsComponent,
  AttachmentComponent,
  AttachmentsComponent,
  TabbedContainerComponent,
  ProcessTabComponent,
  DocumentTabComponent,
  ProcessesTabBarComponent,
  ControlErrorComponent,
  ApiErrorComponent,
  NotFoundComponent,
  SignedComponent,
  RejectedComponent,
  NavbarComponent,
  NameInitials,
  PrettifyXML,
  MenuItemComponent,
  SearchBarComponent,
  DropdownComponent,
  DateDropdownComponent,
  FileInputComponent,
  VirtualScrollComponent,
  CircleComponent,
  AuthNavbarComponent,
  LoadingComponent,
  WorkspaceComponent,
  CommentsComponent,
  SignersComponent,
  CommentsSectionComponent,
  SignDocumentComponent,
  SignerInfoComponent,
  ViewerComponent,
  ButtonsBarV2Component,
  IFPDFComponent,
  SignModalComponent,
  SignatureFormComponent,
  TitleNavbarComponent,
  LogoComponent,
  ProfileImageComponent,
  DocumentDetailInformationComponent,
  SignedListContainerComponent,
  DocumentAttachmentsContainerComponent,
  NotHiredComponent,
  LegalGridComponent,
  LegalGridV2Component,
  ReviewersListContainerComponent,
  ReviewerComponent,
  TableComponent,
  TabsComponent,
  TabComponent,
  ReviewedComponent,
  PopoverComponent,
  HistoryComponent,
  SectionToggleComponent,
  ApproversListContainerComponent,
  ApproverComponent,
  ApplicantsListContainerComponent,
  ApplicantComponent,
  AssignmentDialogComponent,
  AssignmentModalSuccessComponent,
  ButtonComponent,
  ValidateRfcComponent,
  IdentityValidationModalComponent,
  LiteraxStepperComponent,
  LiteraxStepComponent,
  ToValidateComponent,
  IdentityValidationFormComponent,
  ViewImageModalComponent,
  LiteraxFormFieldComponent,
  UploadButtonControlComponent,
  DrawerComponent,
  TycModalComponent,
  DocumentTitleComponent,
  ExpiredLinkComponent,
  WorkspaceLayoutComponent,
  BackButtonComponent,
  ModalSendSignedDocComponent,
  ModalVerifyEmailCodeComponent
];

const directives = [
  ColorsHex,
  ControlErrorsDirective,
  ControlErrorContainerDirective,
  ValidateSignatureTypeDirective,
  FormSubmitDirective,
  FileDndDirective,
  OnDragDirective,
  OnDropDirective,
  OnDragEnterDirective,
  OnDragLeaveDirective,
  OnDragStartDirective,
  OnDragEndDirective,
  OnDragOverDirective,
  DebounceClickDirective,
  ClickStopPropagationDirective,
  DynamicTabsDirective,
  ValidatePermissionDirective,
  LiteraxStepperNextDirective,
  LiteraxStepperPreviousDirective,
];

const pipes = [
  TranslateMomentDatePipe,
  SortByPipe,
  ValueFromColumn,
  DynamicPipe,
  UnixtimestampToDatePipe,
];

@NgModule({
  imports: [modules, EffectsModule.forFeature([FreeDocumentEffects])],
  declarations: [
    components,
    directives,
    pipes,
    SignatureCanvasComponent,
    RegisterPaymentComponent,
    RegisterPaymentContainerComponent,
    E404Component,
    DigitalSignatureFormComponent,
    ToggleSwitchComponent,
    CalendarActiviyComponent,
    TaskComponent,
    MenuButtonDocumentComponent,
    LegalRepresentativesDialogComponent,
    LenguageComponent,
    MessageDialogComponent,
    E404ValidateTokenComponent,
    ComposeAttachmentFileComponent,
    ModalAttachmentComponent,
    ConfirmDialogApprovalComponent,
    DelegateProcessDialogComponent,
    ConfirmDialogDelegateComponent,
    ConfirmationTycModalComponent,
    RegistrationSuccessModalComponent,
    RequestNewLinkDialogComponent,
    AcceptModalComponent,
    OnlyofficeEditorComponent,
    ActionModalComponent,
    VerifyEmailSuccessModalComponent,
  ],
  exports: [
    modules,
    components,
    directives,
    pipes,
    ToggleSwitchComponent,
    CalendarActiviyComponent,
    MenuButtonDocumentComponent,
    LenguageComponent,
  ],
  entryComponents: [
    ProcessTabComponent,
    ProcessesTabBarComponent,
    DocumentTabComponent,
    TabbedContainerComponent,
    ControlErrorComponent,
    ApiErrorComponent,
    SignModalComponent,
    RegisterPaymentContainerComponent,
    LegalRepresentativesDialogComponent,
    MessageDialogComponent,
    IdentityValidationModalComponent,
    ViewImageModalComponent,
    TycModalComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [
    { provide: NZ_I18N, useValue: es_ES },
    { provide: NZ_ICONS, useValue: icons },
    NzTableStyleService,
  ],
})
export class SharedModule {}
