/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientService } from '@literax/services/clientV3/client.service';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'literax-email-validation',
  templateUrl: './email-validation.component.html',
  styleUrls: ['./email-validation.component.scss'],
})
export class EmailValidationComponent implements OnInit {
  public formEmailValidation = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  isEmailSent = false;
  email: string;
  countries = [];

  constructor(
    private clientService: ClientService,
    private translate: TranslateService,
    private modalService: NzModalService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  ngOnInit() {}

  validateClientEmail() {
    if (this.formEmailValidation.valid) {
      this.email = this.formEmailValidation.get('email').value;
      this.clientService.validateEmail({ email: this.email }).subscribe(
        () => {
          this.isEmailSent = true;
        },
        (error) => {
          this.modalService.error({
            nzTitle: 'Error!',
            nzContent: error.message,
          });
        }
      );
    } else {
      this.formEmailValidation.markAllAsTouched();
    }
  }
}
