export const LITERAX_DEFAULT_ERRORS: ErrorsMap = {
  required: `LTX-COMPONENTS.ERRORS.REQUIRED`,
  email: `LTX-COMPONENTS.ERRORS.EMAIL`,
  min: `LTX-COMPONENTS.ERRORS.MIN`,
  max: `LTX-COMPONENTS.ERRORS.MAX`,
  minlength: `LTX-COMPONENTS.ERRORS.MINLENGTH`,
  maxlength: `LTX-COMPONENTS.ERRORS.MAXLENGTH`,
  pattern: `LTX-COMPONENTS.ERRORS.PATTERN`,
  rfc: `LTX-COMPONENTS.ERRORS.RFC`,
  password: `LTX-COMPONENTS.ERRORS.PASSWORD`,
  notMatched: `LTX-COMPONENTS.ERRORS.NOTMATCHED`,
};

export interface ErrorsMap {
  [key: string]: string;
}
