import {
  ClientsResponse,
  ICreateClient,
  ICreateClientResponse,
  IRequestValidationEmail,
  IRequestVerificationCode,
  IValidateClient,
  IVerifyCode,
} from '@literax/models/client.model';

import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientService extends BaseService<any> {
  constructor(http: HttpClient) {
    super(http);
  }

  public validateClient(clientData: IValidateClient): Observable<any> {
    const path = `/clients/validate`;
    return this.post(path, clientData, true);
  }

  public getClients(): Observable<ClientsResponse> {
    return this.get(`/clients`);
  }

  public createClient(
    clientData: ICreateClient
  ): Observable<ICreateClientResponse> {
    return this.post(`/clients`, clientData, true);
  }

  public requestVerificationCode(
    requestVerificationCode: IRequestVerificationCode
  ): Observable<string> {
    return this.post(
      '/guests/onboarding/verification_code',
      requestVerificationCode,
      true
    );
  }

  public verifyCode(verifyCode: IVerifyCode): Observable<string>{
    return this.post(
      '/guests/onboarding/verify_code',
      verifyCode,
      true
    );
  }

  public validateEmail(
    requestVerificationCode: IRequestValidationEmail
  ): Observable<string> {
    return this.post(
      '/guests/onboarding/validate_email',
      requestVerificationCode,
      true
    );
  }

}
