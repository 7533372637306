import * as signatureActions from '@literax/modules/documents/store/signature/signature.actions';

import { Actions, ofType } from '@ngrx/effects';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { EDocumentRequest } from '@literax/enums/document.enum';
import { IAppState } from '@literax/store';
import { ICreateSignatureRequest } from '@literax/models/signature.model';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Store } from '@ngrx/store';
import { UpdateFreeDocument } from '@literax/store/free-document/free-document.actions';
import { take } from 'rxjs/operators';

import { UntilDestroy } from '@ngneat/until-destroy';
import { DomSanitizer } from '@angular/platform-browser';
@UntilDestroy()
@Component({
  selector: 'literax-identity-validation-modal',
  templateUrl: './identity-validation-modal.component.html',
  styleUrls: ['./identity-validation-modal.component.scss'],
})
export class IdentityValidationModalComponent implements OnInit, AfterViewInit {
  faceMatch = false;
  documentRequest: EDocumentRequest = EDocumentRequest.NO_REQUESTED;
  signRequestValidationTypeId: number;
  plusSignature = false;
  signatureBodyRequest: ICreateSignatureRequest;
  nameDocument: string;
  documentId: number;
  typeSignatureSession = true;
  tokenData: string;
  isToValidate: boolean;
  unattendedLink: string;
  sanitizationUrl: any;

  // start biometrics nz stepper variables
  // steps = [
  //   {
  //     title: 'INE (frente)',
  //     key: 'front',
  //   },
  //   {
  //     title: 'INE (reverso)',
  //     key: 'back',
  //   },
  //   {
  //     title: 'Identificación (frente)',
  //     key: 'front',
  //   },
  //   {
  //     title: 'Identificación (reverso)',
  //     key: 'back',
  //   },
  //   {
  //     title: 'Biometricos',
  //     key: 'biometric',
  //   },
  //   {
  //     title: 'Firma',
  //     key: 'form',
  //   },
  // ];
  steps = [
    // {
    //   title: 'Captura de INE (frente)',
    //   key: 'front',
    // },
    // {
    //   title: 'Captura de INE (reverso)',
    //   key: 'back',
    // },
    // {
    //   title: 'Captura de biométricos',
    //   key: 'biometric',
    // },
    {
      title: 'Validación de biométricos con INE',
      key: 'biometric',
    },
    {
      title: 'Firma',
      key: 'form',
    },
  ];
  step = 0;
  currentStep = this.steps[this.step];
  // currentStep: { title: string; key: string; };
  @Input() signBehaviorButton: Subject<void> = new Subject();
  @Input() nextBehaviorButton: Subject<void> = new Subject();
  signProcessInitiated = false;
  canSign: BehaviorSubject<boolean> = new BehaviorSubject(true);
  nextButtonDisabled: BehaviorSubject<boolean> = new BehaviorSubject(true);
  @ViewChild('recordBiometric') recordBiometricElement: ElementRef;
  @ViewChild('documentFront') documentFront: ElementRef<HTMLDivElement>;
  @ViewChild('documentBack') documentBack: ElementRef<HTMLDivElement>;
  @ViewChild('biometricVideo') biometricVideo: ElementRef<HTMLDivElement>;

  rbvComponentInstanceFront: HTMLElement;
  rbvComponentInstanceBack: HTMLElement;
  rbvComponentInstanceVideo: HTMLElement;

  makeSignature: Subject<ICreateSignatureRequest> = new Subject();

  constructor(
    private actions$: Actions,
    private modalRef: NzModalRef,
    private store: Store<IAppState>,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    // const ineRequestedSteps = [0, 1];
    // const officialIDSteps = [2, 3];
    // const biometricSteps = [4];
    // const signatureSteps = [5];
    // const ineRequestedSteps = [0, 1];
    // const biometricSteps = [0, 1, 2];
    // const ineBiometricRequestedSteps = [0, 1, 3];
    // const signatureSteps = [4];
    const ineRequestedSteps = [0, 1];
    const biometricSteps = [0, 1, 2];
    const ineBiometricRequestedSteps = [0];
    const signatureSteps = [1];

    this.sanitizationUrl = this.getSatinizatedUrl(this.unattendedLink);

    // this.steps = this.steps.filter((step, index) => {
    //   if (
    //     this.documentRequest === EDocumentRequest.INE &&
    //     ineRequestedSteps.includes(index)
    //   ) {
    //     return step;
    //   }

    //   if (
    //     this.documentRequest === EDocumentRequest.ID_DOCUMENT &&
    //     officialIDSteps.includes(index)
    //   ) {
    //     return step;
    //   }

    //   if (this.faceMatch && biometricSteps.includes(index)) {
    //     return step;
    //   }

    //   if (signatureSteps.includes(index)) {
    //     return step;
    //   }
    // });
    this.steps = this.steps.filter((step, index) => {
      if (
        this.signRequestValidationTypeId === 2 &&
        ineRequestedSteps.includes(index)
      ) {
        return step;
      }

      if (
        this.signRequestValidationTypeId === 3 &&
        biometricSteps.includes(index)
      ) {
        return step;
      }

      if (
        this.signRequestValidationTypeId === 4 &&
        ineBiometricRequestedSteps.includes(index)) {
        return step;
      }

      if (signatureSteps.includes(index)) {
        return step;
      }
    });
    // this.currentStep = this.steps[0];

    this.signBehaviorButton.subscribe((data) => {
      this.makeSignature.next(this.signatureBodyRequest);
    });

    this.nextBehaviorButton.subscribe((data) => {
      this.nextStep();
    });

    // this.isToValidate =
    //   this.documentRequest !== EDocumentRequest.NO_REQUESTED;
    this.isToValidate = this.signRequestValidationTypeId !== null &&
      this.signRequestValidationTypeId !== 1;
    this.actions$
      .pipe(
        ofType(
          signatureActions.registerSignatureSuccess
        ),
        take(1)
      )
      .subscribe(() => {
        if (this.tokenData) {
          this.store.dispatch(
            UpdateFreeDocument({ payload: { token: this.tokenData } })
          );
        }
        this.modalRef.destroy();
      });
  }

  ngAfterViewInit(): void {
    console.log(this.steps);
    // this.buildBiometricComponentForStep(this.step);
  }

  getSatinizatedUrl(url: string) {
    const satinizatedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    console.log(satinizatedUrl);
    return satinizatedUrl;
  }

  // public get eDocumentRequest(): typeof EDocumentRequest {
  //   return EDocumentRequest;
  // }

  handleInePicture(event, type) {
    const file: File = event.detail.file;

    if (type === 'id_front') {
      if (file && this.step === 0) {
        this.signatureBodyRequest.electronicSignature.idImageFront = file;
        this.nextButtonDisabled.next(false);
      }
    }

    if (type === 'id_back') {
      if (file && this.step === 1) {
        this.signatureBodyRequest.electronicSignature.idImageBack = file;
        this.nextButtonDisabled.next(false);
      }
    }

    if (type === 'video') {
      event.target.getSeekableBlob(event.detail.file, (seekeableBlob) => {
        const finalFile = new File([seekeableBlob], 'biometric.webm', {
          type: 'video/webm',
        });
        if (finalFile && (this.step === 0 || this.step === 2)) {
          // this.data.signature.video = finalFile;
          this.nextButtonDisabled.next(false);
        }
      });
    }
  }

  @HostListener('window:message', ['$event'])
  Message(event: MessageEvent) {
    if (event.data && event.data.code === 1) {
      this.nextButtonDisabled.next(false);
    }
  }

  buildBiometricComponentForStep(step) {
    console.log("🚀 ~ IdentityValidationModalComponent ~ buildBiometricComponentForStep ~ step:", step)
    console.log("🚀 ~ IdentityValidationModalComponent ~ buildBiometricComponentForStep ~ currentStep:", this.currentStep)
    // if (this.documentRequest === EDocumentRequest.NO_REQUESTED) {
    //   return;
    // }
    if (this.signRequestValidationTypeId === null || this.signRequestValidationTypeId === 1) {
      return;
    }

    if (step === 0 && (this.signRequestValidationTypeId === 2 || this.signRequestValidationTypeId === 3 || this.signRequestValidationTypeId === 4)) {
      this.rbvComponentInstanceFront = document.createElement(
        'record-biometric-video'
      );
      this.rbvComponentInstanceFront.setAttribute('width', '320');
      this.rbvComponentInstanceFront.setAttribute('height', '240');
      this.rbvComponentInstanceFront.setAttribute('show-controls', 'true');
      this.rbvComponentInstanceFront.setAttribute('threshold', '0.6');
      this.rbvComponentInstanceFront.setAttribute('match-faces', '1');
      this.rbvComponentInstanceFront.setAttribute('crop', 'true');
      this.rbvComponentInstanceFront.setAttribute('mode', 'photo');
      this.rbvComponentInstanceFront.setAttribute('camera', 'back');
      this.rbvComponentInstanceFront.addEventListener('recordFinished', (e) =>
        this.handleInePicture(e, 'id_front')
      );

      this.documentFront.nativeElement.appendChild(
        this.rbvComponentInstanceFront
      );
      return;
    }

    if (step === 1 && (this.signRequestValidationTypeId === 2 || this.signRequestValidationTypeId === 3 || this.signRequestValidationTypeId === 4)) {
      this.rbvComponentInstanceFront.removeAllListeners();
      this.rbvComponentInstanceFront.remove();
      this.rbvComponentInstanceBack = document.createElement(
        'record-biometric-video'
      );
      this.rbvComponentInstanceBack.setAttribute('width', '320');
      this.rbvComponentInstanceBack.setAttribute('height', '240');
      this.rbvComponentInstanceBack.setAttribute('show-controls', 'true');
      this.rbvComponentInstanceBack.setAttribute('threshold', '0.6');
      this.rbvComponentInstanceBack.setAttribute('match-faces', '0');
      this.rbvComponentInstanceBack.setAttribute('crop', 'true');
      this.rbvComponentInstanceBack.setAttribute('mode', 'photo');
      this.rbvComponentInstanceBack.setAttribute('camera', 'back');
      this.rbvComponentInstanceBack.addEventListener('recordFinished', (e) =>
        this.handleInePicture(e, 'id_back')
      );
      this.documentBack.nativeElement.appendChild(
        this.rbvComponentInstanceBack
      );
      return;
    }

    if ((step === 2 && this.signRequestValidationTypeId === 3) || (step === 2 && this.signRequestValidationTypeId === 4)) {
      if (step === 2 && this.signRequestValidationTypeId === 4) {
        this.rbvComponentInstanceBack.removeAllListeners();
        this.rbvComponentInstanceBack.remove();
      }
      this.rbvComponentInstanceVideo = document.createElement(
        'record-biometric-video'
      );
      this.rbvComponentInstanceVideo.setAttribute('width', '320');
      this.rbvComponentInstanceVideo.setAttribute('height', '240');
      this.rbvComponentInstanceVideo.setAttribute('show-controls', 'true');
      this.rbvComponentInstanceVideo.setAttribute('threshold', '0.6');
      this.rbvComponentInstanceVideo.setAttribute('match-faces', '1');
      this.rbvComponentInstanceVideo.setAttribute('crop', 'false');
      this.rbvComponentInstanceVideo.setAttribute('max-record-time', '30');
      this.rbvComponentInstanceVideo.setAttribute('show-face-boxes', 'false');
      this.rbvComponentInstanceVideo.setAttribute('mode', 'video');
      this.rbvComponentInstanceVideo.addEventListener('recordFinished', (e) =>
        this.handleInePicture(e, 'video')
      );
      this.biometricVideo.nativeElement.appendChild(
        this.rbvComponentInstanceVideo
      );
      return;
    }
    if (this.steps[step].key === 'form') {
      this.rbvComponentInstanceVideo.removeAllListeners();
      this.rbvComponentInstanceVideo.remove();
    }
  }

  nextStep() {
    this.step++;
    this.currentStep = this.steps[this.step];
    this.nextButtonDisabled.next(true);
    // this.buildBiometricComponentForStep(this.step);
  }
}
