import { Action, createReducer, on } from '@ngrx/store';
import {
  ClearSelectedOperation,
  ClearSelectedWebService,
  ClearUserSelectedIntegrator,
  CreateOperationuSuccess,
  CreateUserIntegratorError,
  CreateUserIntegratorSuccess,
  CreateWebServiceConfiguSuccess,
  DeleteOperationSuccess,
  DeleteUserIntegratorSuccess,
  DeleteWebServiceConfigSuccess,
  GetEventsLogIndexSuccess,
  GetOperationsIndexSuccess,
  GetUserIntegratorIndexSuccess,
  GetWebServiceConfigIndexSuccess,
  ResetDefaults,
  ShowOperationSuccess,
  ShowUserIntegratorSuccess,
  ShowWebServiceConfigHeadersSuccess,
  ShowWebServiceConfigSuccess,
  UpdateOperationSuccess,
  UpdateUserIntegratorSuccess,
  UpdateWebServiceConfigSuccess,
  clearEventLogSortParams,
  setEventsLogSort,
  setOperationsSort,
  setUserIntegratorSort,
  setWebServiceConfigurationsSort
} from './api.actions';
import {
  IApiConfigurationsInterface,
  initialApiConfigurationsState
} from './api.state';

const reducer = createReducer(
  initialApiConfigurationsState,

  on(GetUserIntegratorIndexSuccess, (state, action) => {
    const newUsers = [...state.users, ...action.payload.client_users];
    const newPage =
      action.payload.client_users.length > 0
        ? state.user_curret_page + 1
        : state.user_curret_page;
    return {
      ...state,
      users: newUsers,
      user_curret_page: newPage,
      create_user: false
    };
  }),

  on(ShowUserIntegratorSuccess, (state, action) => ({
    ...state,
    selected_user: action.payload.client_user
  })),

  on(GetWebServiceConfigIndexSuccess, (state, action) => {
    const newPage =
      action.payload.webservice_configs.length > 0
        ? state.webservice_curret_page + 1
        : state.webservice_curret_page;

    return {
      ...state,
      webservice_configs: action.payload.webservice_configs,
      webservice_curret_page: newPage,
      pagination: action.payload.pagination
    };

  }),

  on(setWebServiceConfigurationsSort, (state, action) => ({
    ...state,
    web_service_sort_params: action.payload.sort,
    webservice_configs: [],
    webservice_curret_page: 1
  })),

  on(setOperationsSort, (state, action) => ({
    ...state,
    operations_curret_page: 1,
    operation_sort_params: action.payload.sort,
    operations: []
  })),

  on(setUserIntegratorSort, (state, action) => ({
    ...state,
    user_sort_params: action.payload.sort,
    user_curret_page: 1,
    users: []
  })),

  on(ShowWebServiceConfigSuccess, (state, action) => ({
    ...state,
    selected_webservice_config: action.payload.webservice_config
  })),

  on(ClearSelectedWebService, (state, action) => ({
    ...state,
    selected_webservice_config: null
  })),

  on(ClearUserSelectedIntegrator, (state, action) => ({
    ...state,
    selected_user: null
  })),

  on(GetOperationsIndexSuccess, (state, action) => {
    const newPage =
      action.payload.operations.length > 0
        ? state.operations_curret_page + 1
        : state.operations_curret_page;
    return {
      ...state,
      operations: action.payload.operations,
      operations_curret_page: newPage,
      pagination: action.payload.pagination
    };
  }),

  on(ShowOperationSuccess, (state, action) => ({
    ...state,
    selected_operation: action.payload.operation
  })),

  on(ClearSelectedOperation, (state, action) => ({
    ...state,
    selected_operation: null
  })),

  on(CreateUserIntegratorError, (state, action) => ({
    ...state,
    create_user_errors: action.payload,
    create_user: false
  })),

  on(CreateUserIntegratorSuccess, (state, action) => ({
    ...state,
    create_user: action.payload,
    users: [],
    user_curret_page: 1
  })),

  on(UpdateUserIntegratorSuccess, (state, action) => ({
    ...state,
    create_user: action.payload,
    users: [],
    user_curret_page: 1
  })),

  on(DeleteUserIntegratorSuccess, (state, action) => ({
    ...state,
    users: [],
    user_curret_page: 1
  })),

  on(CreateWebServiceConfiguSuccess, (state, action) => ({
    ...state,
    create_webservice: action.payload,
    webservice_configs: [],
    webservice_curret_page: 1
  })),

  on(UpdateWebServiceConfigSuccess, (state, action) => ({
    ...state,
    update_webservice: action.payload,
    webservice_configs: [],
    webservice_curret_page: 1
  })),

  on(CreateOperationuSuccess, (state, action) => ({
    ...state,
    create_operation: action.payload,
    operations: [],
    operations_curret_page: 1
  })),

  on(UpdateOperationSuccess, (state, action) => ({
    ...state,
    update_operation: action.payload,
    operations: [],
    operations_curret_page: 1
  })),

  on(ResetDefaults, (state, action) => ({
    ...state,
    ...initialApiConfigurationsState,
    webservice_configs:[],
    pagination: null
  })),

  on(setEventsLogSort, (state, action) => ({
    ...state,
    events: [],
    events_sort_params: {
      ...state.events_sort_params,
      ...action.payload,
      page: 1
    }
  })),

  on(GetEventsLogIndexSuccess, (state, action) => {
    const newEvents = [...state.events, ...action.payload];
    const newPage =
      action.payload.length > 0
        ? state.events_sort_params.page + 1
        : state.events_sort_params.page;
    return {
      ...state,
      events: newEvents,
      events_sort_params: { ...state.events_sort_params, page: newPage }
    };
  }),

  on(clearEventLogSortParams, (state, action) => ({
    ...state,
    events: [],
    events_sort_params: {
      sort: 'id_event:desc',
      page: 1
    }
  })),

  on(DeleteWebServiceConfigSuccess, (state, action) => ({
    ...state,
    webservice_configs: [],
    webservice_curret_page: 1
  })),

  on(DeleteOperationSuccess, (state, action) => ({
    ...state,
    operations: [],
    operations_curret_page: 1
  })),

  on(ShowWebServiceConfigHeadersSuccess, (state, action) => ({
    ...state,
    webservice_configs_headers: action.payload.webservice_config_headers
  })),
);

export function apiConfigurationsReducer(
  state: IApiConfigurationsInterface,
  action: Action
) {
  return reducer(state, action);
}
