import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientService } from '@literax/services/clientV3/client.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'literax-modal-verify-email-code',
  templateUrl: './modal-verify-email-code.component.html',
  styleUrls: ['./modal-verify-email-code.component.scss'],
})
export class ModalVerifyEmailCodeComponent {
  @Input() data: { email: string };

  public verifyCodeForm = new FormGroup({
    verificationCode: new FormControl('', [Validators.required]),
  });

  constructor(
    private clientService: ClientService,
    private modalService: NzModalService,
    private modalRef: NzModalRef
  ) {}

  verifyCode() {
    if (this.verifyCodeForm.valid) {
      this.modalRef.close(true);
      // this.clientService
      //   .verifyCode({
      //     email: this.verifyCodeForm.value.email,
      //     verificationCode: this.verifyCodeForm.value.verificationCode,
      //   })
      //   .subscribe(
      //     () => {
      //       this.modalRef.close(true);
      //     },
      //     (error) => {
      //       this.modalService.error({
      //         nzTitle: 'Error!',
      //         nzContent: error.message,
      //       });
      //     }
      //   );
    }
  }

  resendCode() {
    this.clientService
      .requestVerificationCode({
        fullName: this.data.email,
        email: this.data.email,
      })
      .subscribe(
        () => {
          this.modalService.success({
            nzTitle: 'Success!',
            nzContent: 'Verification code has been sent to your email',
          });
        },
        (error) => {
          this.modalService.error({
            nzTitle: 'Error!',
            nzContent: error.message,
          });
        }
      );
  }
}
