<div *ngIf="lookFeel$ | async" class="guest" [ngStyle]="{
    background: (lookFeel$ | async)?.webNavigationColor
      ? (lookFeel$ | async)?.webNavigationColor
      : '#434343'
  }">
  <div class="logo-web-container" [ngStyle]="{
      background: (lookFeel$ | async)?.webNavigationColor
        ? (lookFeel$ | async)?.webNavigationColor
        : '#434343'
    }">
    <img class="logo-img" [src]="(lookFeel$ | async)?.webLogo64 || logoUrl" />
  </div>
  <div class="toolbar-container-guest" [ngStyle]="{
      background: (lookFeel$ | async)?.webEmphasisColor
        ? (lookFeel$ | async)?.webEmphasisColor
        : '#001529'
    }">
    <div class="tool-bar-icons-container-guest">
      <span nz-icon nzType="global" nzTheme="outline" class="icon" nz-dropdown nzTrigger="click"
        [nzDropdownMenu]="lang"></span>
      <nz-dropdown-menu #lang="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item (click)="setLang('es')">
            <span>Español</span>
          </li>
          <li nz-menu-divider></li>
          <li nz-menu-item (click)="setLang('en')">
            <span>English</span>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</div>

<div *ngIf="(lookFeel$ | async) === null && standalone" class="guest" [ngStyle]="{
    background: '#434343'
  }">
  <div class="logo-web-container" [ngStyle]="{
      background:  '#434343'
    }">
    <img class="logo-img" [src]=" logoUrl" />
  </div>
  <div class="toolbar-container-guest" [ngStyle]="{
      background: '#00000073'
    }">
    <div class="tool-bar-icons-container-guest">
      <span nz-icon nzType="global" nzTheme="outline" class="icon" nz-dropdown nzTrigger="click"
        [nzDropdownMenu]="lang"></span>
      <nz-dropdown-menu #lang="nzDropdownMenu">
        <ul nz-menu>
          <li nz-menu-item (click)="setLang('es')">
            <span>Español</span>
          </li>
          <li nz-menu-divider></li>
          <li nz-menu-item (click)="setLang('en')">
            <span>English</span>
          </li>
        </ul>
      </nz-dropdown-menu>
    </div>
  </div>
</div>
<div *ngIf="isView" class="banner banner-big info">
  <p>
    <b>{{ 'BANNER-MESSAGES.MESSAGE_NOTICE' | translate }}</b>
    {{ 'BANNER-MESSAGES.MESSAGE_WARNING' | translate }}
  </p>
</div>