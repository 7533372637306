<literax-auth-navbar
  [title]="'AUTH.TITLE.NAVBAR' | translate"
></literax-auth-navbar>
<div class="main-container">
  <section id="stepper">
    <nz-steps [nzCurrent]="currentStep">
      <nz-step></nz-step>
      <nz-step></nz-step>
      <nz-step *ngIf="!isTrial"></nz-step>
      <nz-step></nz-step>
    </nz-steps>

    <div class="steps-content">
      <div id="step0" *ngIf="currentStep === 0">
        <h3 class="step-title">{{ 'AUTH.STEP' | translate }}</h3>
        <h6 class="step-subtitle">{{ 'AUTH.NOTICE' | translate }}</h6>
        <div class="legal-buttons">
          <button
            nz-button nzType="primary"
            class="personal-btn"
            type="button"
            (click)="togglePersonType(false)"
            [ngStyle]="{
              'background-color': !isLegalPerson
                ? selectedCardColor
                : 'transparent',
              border: !isLegalPerson ? '0' : 'solid 1px #9E9E9E',
              color: !isLegalPerson ? 'white' : '#757575'
            }"
          >
            <i class="fal fa-user-alt"></i>
            {{ 'AUTH.FIELDS.ACCOUNT_PERSONAL' | translate }}
          </button>
          <button
            nz-button nzType="default"
            class="enterprise-btn"
            type="button"
            (click)="togglePersonType(true)"
            [ngStyle]="{
              'background-color': isLegalPerson
                ? selectedCardColor
                : 'transparent',
              border: isLegalPerson ? '0' : 'solid 1px #9E9E9E',
              color: isLegalPerson ? 'white' : '#757575'
            }"
          >
            <i class="fal fa-building"></i>
            {{ 'AUTH.FIELDS.ACCOUNT_ENTERPRISE' | translate }}
          </button>
        </div>
        <form
          [formGroup]="form"
          class="mat-elevation-z6 card"
        >
          <div>
            <div class="row">
              <div
                formGroupName="user"
                class="col-md-4"
                *ngIf="!isLegalPerson"
              >
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.NAME' | translate"
                    formControlName="name"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div
                formGroupName="user"
                class="col-md-4"
                *ngIf="!isLegalPerson"
              >
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.LAST_NAME' | translate"
                    formControlName="last_name"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div
                formGroupName="user"
                class="col-md-4 col-md-3-mobile"
                *ngIf="!isLegalPerson"
              >
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.RFC' | translate"
                    [upperCase]="true"
                    formControlName="rfc"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div class="col-md-4" *ngIf="isLegalPerson">
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.BUSINESS_NAME' | translate"
                    formControlName="business_name"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div class="col-md-4" *ngIf="isLegalPerson">
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.TRADE_NAME' | translate"
                    formControlName="tradename"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div class="col-md-4" *ngIf="isLegalPerson">
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.RFC' | translate"
                    [upperCase]="true"
                    formControlName="tax_id"
                  >
                  </literax-form-field>
                </div>
              </div>
            </div>

            <div formGroupName="user" class="row" *ngIf="!isLoggedIn">
              <div class="col-md-4">
                <div class="gdx-form-group">
                  <literax-form-field 
                    [label]="'AUTH.FIELDS.EMAIL' | translate"
                    [pattern]="emailPattern"
                    formControlName="email"
                  >
                  </literax-form-field>
                </div>
              </div>

              <div class="col-md-4" *ngIf="!isLoggedIn">
                <div class="gdx-form-group">
                  <literax-form-field 
                    type="password"
                    [label]="'AUTH.FIELDS.PASSWORD' | translate"
                    formControlName="password"
                  >
                  </literax-form-field>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-md-3-mobile-country">
              <div class="gdx-form-group">
                <label>
                  {{ 'AUTH.FIELDS.COUNTRY' | translate }}
                </label>
                <div>
                  <nz-select
                    formControlName="country"
                    nzShowSearch
                  >
                    <nz-option 
                      *ngFor="let option of countries" 
                      [nzValue]="option" 
                      [nzLabel]="option.label"
                    >
                    </nz-option>
                  </nz-select>
                  <literax-api-error
                    [control]="form.get('country')"
                  ></literax-api-error>
                </div>
                

              </div>
            </div>

            <div class="col-md-3 col-md-2-mobile">
              <div class="gdx-form-group">
                <literax-form-field
                  [label]="'AUTH.FIELDS.POSTAL_CODE' | translate"
                  [maxlength]="5"
                  formControlName="postal_code"
                >
                </literax-form-field>
              </div>
            </div>

            <div class="col-md-4">
              <div class="gdx-form-group">
                <literax-form-field
                  [label]="'AUTH.FIELDS.PHONE' | translate"
                  formControlName="phone"
                >
                </literax-form-field>
              </div>
            </div>
          </div>

          <div class="buttons">
            <button
              nz-button nzType="default"
              class="back-btn"
              (click)="onCancelClicked()"
              tabindex="5"
            >
              {{ 'AUTH.BUTTONS.CANCEL' | translate }}
            </button>
            <button
              nz-button nzType="primary"
              class="registration-btn button--primary"
              [disabled]="!form.valid"
              tabindex="4"
              type="button"
              (click)="validateClientInfo()"
            >
              {{ 'AUTH.BUTTONS.NEXT' | translate }}
            </button>
          </div>
        </form>
      </div>
      <div id="step1" *ngIf="currentStep === 1">
        <h3 class="step-title">{{ 'PLANS.TITLE' | translate }}</h3>
        <div class="mat-elevation-z6 card">
          <div class="container-fluid plans-container">
            <div class="row">
              <div
                *ngFor="let plan of plans; let i = index"
                class="col-lg-3 col-sm-5 col-xs-12 plan-card mat-elevation-z3"
                (click)="onCardClicked(i, plan?.productId, plan?.isTrial)"
              >
                <div
                  class="plan-title"
                  [ngStyle]="{
                    'background-color':
                      selectedCard === i
                        ? selectedCardColorPlanHeader
                        : defaultCardColor
                  }"
                >
                  <div
                    [ngStyle]="{
                      color: selectedCard === i ? 'white' : newTextColor
                    }"
                    class="size"
                  >
                    {{ plan?.name }}
                  </div>
                </div>
                <div class="plan-specs">
                  <div
                    *ngFor="let product of plan?.description?.split('|')"
                    class="spec"
                  >
                    <div
                      [ngStyle]="{
                        color:
                          selectedCard === i
                            ? selectedCardColorPlanDescription
                            : newArrowGray
                      }"
                    >
                      - {{ product }}
                    </div>
                  </div>
                </div>
                <div
                  class="plan-price"
                  [ngStyle]="{
                    color:
                      selectedCard === i
                        ? selectedCardColorPlanDescription
                        : newTextColor
                  }"
                >
                  <div>$ {{ plan?.price }}</div>
                  <div
                    class="disclaimer"
                    [ngStyle]="{
                      color:
                        selectedCard === i
                          ? selectedCardColorPlanDescription
                          : newArrowGray
                    }"
                  >
                    {{ 'PLANS.DISCLAIMER' | translate }}
                  </div>
                </div>
                <div class="plan-buttons"></div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <button
              nz-button nzType="default"
              class="back-btn"
              tabindex="5"
              (click)="pre()"
            >
              {{ 'PLANS.BUTTONS.BACK' | translate }}
            </button>
            <button
              nz-button nzType="primary"
              class="registration-btn button--primary"
              [disabled]="!(!!selectedProduct)"
              tabindex="4"
              type="button"
              (debounceClick)="onPaymentClicked()"
              [debounceTime]="300"
            >
              {{ 'PLANS.BUTTONS.NEXT' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div id="step2" *ngIf="currentStep === 2 && !isTrial">
        <h3>{{ 'PAYMENT.TITLE' | translate }}</h3>
        <div class="mat-elevation-z6 card">
          <div class="payments-container">
            <div
              #paymentWidget
              id="gdx-paymentmanager-widget"
              class="payment-widget-container"
            >
          </div>
          </div>
          <div class="buttons">
            <button
              nz-button nzType="default"
              class="back-btn"
              tabindex="5"
              (click)="pre()"
            >
              {{ 'PAYMENT.BUTTONS.BACK' | translate }}
            </button>
            <button
              nz-button nzType="primary"
              class="registration-btn button--primary"
              [disabled]="!paymentSuccess"
              tabindex="4"
              type="button"
              (debounceClick)="onRegistrationClicked()"
              [debounceTime]="300"
            >
              {{ 'PAYMENT.BUTTONS.NEXT' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div id="step3" *ngIf="currentStep === 3">
        <h3 class="step-title">{{ 'FINISH.TITLE' | translate }}</h3>
        <form class="mat-elevation-z6 card">
          <div class="finish-content">
            <div class="finish-icon" *ngIf="registrationSuccess">
              <i class="fal fa-check-circle"></i>
            </div>
            <h3 class="finish-message" *ngIf="registrationSuccess">
              {{ 'FINISH.MESSAGE' | translate }}
              {{ createClientRequest.user.email }}
            </h3>
            <h3 class="finish-message" *ngIf="registrationSuccess">
              {{ 'FINISH.CONFIRM' | translate }}
            </h3>
            <div class="finish-icon" *ngIf="!registrationSuccess">
              <i class="fal fa-exclamation"></i>
            </div>
            <h3 class="finish-message" *ngIf="!registrationSuccess">
              {{ 'FINISH.ERROR' | translate }}
            </h3>
            <div class="finish-home" *ngIf="registrationSuccess">
              <h4 class="finish-invitation">
                {{ 'FINISH.INVITATION' | translate }}
              </h4>
              <button
                nz-button nzType="primary"
                class="finish-button"
                (click)="goToInterfirma()"
              >
                {{ 'FINISH.BUTTONS.BACK' | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>

<!--<nz-layout>
  <literax-auth-navbar
    [title]="'AUTH.TITLE.NAVBAR' | translate"
    [standalone]="true"
  ></literax-auth-navbar>
  <nz-content>
    <nz-page-header [nzTitle]="'ONBOARDING.TITLE' | translate"></nz-page-header>
    <section id="stepper">
      <nz-steps [nzCurrent]="currentStep" [nzProgressDot]="progressTemplate">
        <nz-step nzTitle="{{ 'AUTH.STEPS.STEP_1' | translate }}"></nz-step>
        <nz-step nzTitle="{{ 'AUTH.STEPS.STEP_2' | translate }}"></nz-step>
        <nz-step nzTitle="{{ 'AUTH.STEPS.STEP_3' | translate }}"></nz-step>
        <nz-step *ngIf="!isTrial" nzTitle="{{ 'AUTH.STEPS.STEP_4' | translate }}"></nz-step>
        <nz-step nzTitle="{{ 'AUTH.STEPS.STEP_5' | translate }}"></nz-step>
      </nz-steps>
      <ng-template
        #progressTemplate
        let-dot
        let-status="status"
        let-index="index"
      >
        <span
          nz-popover
          nzPopoverContent="steps {{ index }} status: {{ status }}"
          style="margin-left: -100%"
        >
          <ng-template [ngTemplateOutlet]="dot"></ng-template>
        </span>
      </ng-template>
  
      <div class="steps-content">
        <div id="step0" *ngIf="currentStep === 0">
          <nz-card [nzActions]="[footer]">
            <form [formGroup]="formEmailConfirmation">
              <div nz-row>
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                  <literax-form-field
                    [required]="true"
                    [label]="'AUTH.FIELDS.EMAIL' | translate"
                    formControlName="email"
                  >
                  </literax-form-field>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                  TODO: Agregarle al hint la descripción correspondiente
                  <literax-form-field
                    [required]="true"
                    [type]="'password'"
                    [label]="'AUTH.FIELDS.PASSWORD' | translate"
                    [hint]="'Descripción del la contraseña' | translate"
                    formControlName="password"
                  >
                  </literax-form-field>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                  <literax-form-field
                    [required]="true"
                    [type]="'password'"
                    [label]="'AUTH.FIELDS.PASSWORD_CONFIRMATION_ALT' | translate"
                    formControlName="confirmPassword"
                  >
                  </literax-form-field>
                  <span class="literax-form-field-error" *ngIf="formEmailConfirmation.get('confirmPassword').touched && formEmailConfirmation.errors?.['notMatched']">
                    <i class="fa fa-times-circle"> </i>
                    {{'LTX-COMPONENTS.ERRORS.NOTMATCHED' | translate}}
                  </span>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                  <label nz-checkbox formControlName="acceptTerms" (ngModelChange)="updateFieldTerms()">
                    {{ 'AUTH.FIELDS.ACCEPT_TERMS_1' | translate }}
                    <a href="https://www.literax.com/wp-content/uploads/2021/06/Terminos-y-condiciones.pdf" target="_blank">
                      {{'AUTH.FIELDS.ACCEPT_TERMS_2' | translate}}
                    </a>
                    {{'AUTH.FIELDS.ACCEPT_TERMS_3' | translate}}
                    <a href="https://www.literax.com/wp-content/uploads/2021/06/aviso_de_privacidad.pdf" target="_blank">
                      {{'AUTH.FIELDS.ACCEPT_TERMS_4' | translate}}
                    </a>
                  </label>
                </div>
              </div>
            </form>
            <ng-template #footer>
              <literax-step-buttons
                [isFirstStep]="true"
                [nexStepButtonEnabled]="formEmailConfirmation.valid && areTermsAccepted"
                (cancel)="onCancelClicked()"
                (next)="sendVerificationCode()"
              ></literax-step-buttons>
            </ng-template>
          </nz-card>
        </div>
        <div id="step1" *ngIf="currentStep === 1">
          <p>{{ 'Selecciona la membresía que mejor se adapte a sus necesidades' | translate }}</p>
          <div class="membership-container">
            <div class="membership-plans">
              <h4>Membresias individuales</h4>
              <p>Persona física</p>
              <div style="display: flex; justify-content: center;">
                <literax-membership-card
                  *ngFor="let item of [].constructor(3); index as i"
                  [title]="'15 transacciones'"
                  [price]="420"
                  [period]="'por mes'"
                  [priceColor]="'#CF1322'"
                  [periodColor]="'#A8071A'"
                  [itemsList]="[
                    '1 usuario',
                    'Firma electrónica',
                    'Firma electrónica plus',
                    'Firma digital',
                    'Secuencia de firmas',
                    'Constancia de conservación',
                    'Centro de ayuda'
                  ]"
                  [iconColor]="'red'"
                  (selectionCard)="goToPayment('individual', i)"
                ></literax-membership-card>
              </div>
            </div>
            <div class="membership-plans">
              <h4>Membresias empresariales</h4>
              <p>
                Persona física con actividad empresarial o Persona moral
              </p>
              <div style="display: flex; justify-content: center;">
                <literax-membership-card
                  *ngFor="let item of [].constructor(3); index as i"
                  [title]="'250 transacciones'"
                  [price]="5350"
                  [period]="'por mes'"
                  [priceColor]="'#595959'"
                  [periodColor]="'#262626'"
                  [itemsList]="[
                    '5 usuario',
                    'Firma electrónica',
                    'Firma electrónica plus',
                    'Firma digital',
                    'Secuencia de firmas',
                    'Constancia de conservación',
                    'Centro de ayuda'
                  ]"
                  [iconColor]="'#595959'"
                  (selectionCard)="goToBilling('moral', i)"
                ></literax-membership-card>
              </div>
            </div>
          </div>
          <p class="membership-footer">
            {{ 'Si desea conocer más planes o un esquema personalizado, dé click ' | translate }}
            <a nz-button nzType="link" (click)="openLinkNewTab('/plans')">{{ 'aquí' | translate }}</a>
          </p>
        </div>
        <div id="step2" *ngIf="currentStep === 2">
          <nz-card [nzActions]="[footer]">
            <form [formGroup]="billingForm">
              <div nz-row nzGutter="12">
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="8">
                  <literax-form-field
                    [required]="true"
                    [label]="'RFC' | translate"
                    [placeholder]="'AAAA010101AAA'"
                    formControlName="taxId"
                  >
                  </literax-form-field>
                </div>
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="16" [nzLg]="16">
                  TODO: Agregarle al hint la descripción correspondiente
                  <literax-form-field
                    [required]="true"
                    [label]="'Razón social' | translate"
                    [hint]="'Descripción del la contraseña' | translate"
                    [placeholder]="'Ingresar'"
                    formControlName="businessName"
                  >
                  </literax-form-field>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                  <literax-form-field
                    [required]="true"
                    [type]="'select'"
                    [label]="'Régimen fiscal' | translate"
                    [placeholder]="'Selecciona' | translate"
                    [options]="[]"
                    formControlName="taxRegime"
                  >
                  </literax-form-field>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                  <literax-form-field
                    [required]="true"
                    [type]="'select'"
                    [label]="'Uso del CFDI' | translate"
                    [placeholder]="'Selecciona' | translate"
                    [options]="[]"
                    formControlName="cfdi"
                  >
                  </literax-form-field>
                </div>
              </div>
              <div nz-row nzGutter="12">
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="8">
                  <literax-form-field
                    [required]="true"
                    [type]="'select'"
                    [label]="'País' | translate"
                    [placeholder]="'Selecciona' | translate"
                    [options]="[]"
                    formControlName="country"
                  >
                  </literax-form-field>
                </div>
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6" [nzLg]="6">
                  <literax-form-field
                    [required]="true"
                    [label]="'Código postal' | translate"
                    [placeholder]="'00000'"
                    formControlName="zipCode"
                  >
                  </literax-form-field>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                  <literax-form-field
                    [type]="'text'"
                    [label]="'Dirección' | translate"
                    [placeholder]="'Ingresar'"
                    formControlName="address"
                  >
                  </literax-form-field>
                </div>
              </div>
              <div nz-row>
                <div nz-col [nzXs]="10" [nzSm]="10" [nzMd]="10" [nzLg]="10">
                  <nz-form-item>
                    <nz-form-label nzNoColon="true" nzFor="phone" nzRequired>{{ 'Teléfono móvil de contacto' | translate }}</nz-form-label>
                    <nz-input-group nzAddOnBefore="+52">
                      <input
                        type="text"
                        nz-input
                        placeholder="00 0000 0000"
                        formControlName="phone"
                      />
                    </nz-input-group>
                  </nz-form-item>
                </div>
              </div>
            </form>
            <ng-template #footer>
              <literax-step-buttons
                [nexStepButtonEnabled]="billingForm.valid"
                (cancel)="pre()"
                (next)="goToPayment()"
              ></literax-step-buttons>
            </ng-template>
          </nz-card>
        </div>
        <div id="step3" *ngIf="currentStep === 3 && !isTrial">
          <nz-card [nzActions]="[footer]">
            <div #paymentWidget></div>
            <ng-template #footer>
              <literax-step-buttons
                [isFirstStep]="true"
                [nexStepButtonEnabled]="formEmailConfirmation.valid && areTermsAccepted"
                (cancel)="pre()"
                (next)="onRegistrationClicked()"
              ></literax-step-buttons>
            </ng-template>
          </nz-card>
        </div>
        <div id="step4" *ngIf="currentStep === 4">
          <nz-card>
            <nz-result
              [nzIcon]="customIconTemplate"
              [nzTitle]="customTitleTemplate"
              [nzSubTitle]="customSubTitleTemplate"
            >
              <ng-template #customIconTemplate>
                <span nz-icon nzType="check-circle" nzTheme="outline" style="color: #52C41A;"></span>
              </ng-template>
              <ng-template #customTitleTemplate>
                <div class="result-title">
                  <h4>
                    Gracias por registrarte a Literax
                  </h4>
                  <br>
                  <h4>
                    ¡Ya puedes aprovechar todas sus ventajas!
                  </h4>
                </div>
              </ng-template>
              <ng-template #customSubTitleTemplate>
                <div nz-typography nzType="secondary" class="result-text">
                  Puedes iniciar sesión desde este aviso o directamente desde nuestro sitio: <a nz-button nzType="link" (click)="goToInterfirma()">
                    {{ 'www.literax.com' | translate }}
                  </a>.<br>
                  Utiliza como Usuario la dirección de correo electrónico verificada y la Contraseña que utilizaste<br>
                  en este proceso de registro.
                </div>
                <div nz-typography nzType="secondary" class="result-text">
                  En caso de que no recuerdes la contraseña podrás recuperarla en <a nz-button nzType="link">
                    {{ '¿Olvidaste tu contraseña?' | translate }}
                  </a><br>
                  Al acceder por primera vez, deberás leer y aceptar los Términos & Condiciones de la aplicación.<br>
                  Recibirás adicionalmente un correo electrónico con la factura de tu servicio pagado.
                </div>
              </ng-template>
              <div nz-result-extra>
                <button nz-button nzType="primary" (click)="goToInterfirma()">{{ 'Iniciar sesión' | translate }}</button>
              </div>
            </nz-result>
          </nz-card>
        </div>
      </div>
    </section>
  </nz-content>
  <nz-footer>
    <span nz-typography nzType="secondary" [innerHTML]="'ONBOARDING.FOOTER.COPYRIGHT' | translate"></span>
    <a nz-button nzType="link" (click)="openLinkNewTab('/privacy_policy')">
      {{ 'ONBOARDING.FOOTER.PRIVACY_POLICY' | translate }}
    </a>
    <a nz-button nzType="link" (click)="openLinkNewTab('/terms')">
      {{ 'ONBOARDING.FOOTER.T&C' | translate }}
    </a>
  </nz-footer>
</nz-layout> -->

<!-- <nz-layout>
  <literax-auth-navbar
    [title]="'AUTH.TITLE.NAVBAR' | translate"
    [standalone]="true"
  ></literax-auth-navbar>
  <nz-content>
    <div class="main-container">
      <nz-card class="card-header-content">
        <h5 class="stepper-title">{{ 'AUTH.STEP_TITLE' | translate }}</h5>
        <nz-steps [nzCurrent]="currentStep" [nzProgressDot]="progressTemplate">
          <nz-step
            nzTitle="{{ 'AUTH.STEPS_ALT.STEP_1' | translate }}"
          ></nz-step>
          <nz-step
            nzTitle="{{ 'AUTH.STEPS_ALT.STEP_2' | translate }}"
          ></nz-step>
          <nz-step
            nzTitle="{{ 'AUTH.STEPS_ALT.STEP_3' | translate }}"
          ></nz-step>
          <nz-step
            nzTitle="{{ 'AUTH.STEPS_ALT.STEP_4' | translate }}"
          ></nz-step>
        </nz-steps>
        <ng-template
          #progressTemplate
          let-dot
          let-status="status"
          let-index="index"
        >
          <span
            nz-popover
            nzPopoverContent="steps {{ index }} status: {{ status }}"
            style="margin-left: -100%"
          >
            <ng-template [ngTemplateOutlet]="dot"></ng-template>
          </span>
        </ng-template>
      </nz-card>
      <nz-card class="card-main-content">
        <section *ngIf="currentStep === 0">
          <div>
            <h1 class="main-title">
              {{ 'AUTH.STEP1_TEXTS.MAIN_TITLE' | translate }}
            </h1>
            <p class="directions">
              {{ 'AUTH.STEP1_TEXTS.DIRECTIONS' | translate }}
            </p>
            <form [formGroup]="formProfile">
              <div nz-row nzGutter="16">
                <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6" [nzLg]="6">
                  <literax-form-field
                    [required]="true"
                    [label]="'AUTH.FIELDS.RFC' | translate"
                    formControlName="taxId"
                    [hint]="'AUTH.FIELDS.RFC_HINT' | translate"
                    placeholder="{{'AUTH.PLACEHOLDERS.RFC' | translate}}"
                  >
                  </literax-form-field>
                </div>
                <div nz-col [nzXs]="14" [nzSm]="14" [nzMd]="14" [nzLg]="14">
                  <literax-form-field
                    [required]="true"
                    [label]="'AUTH.FIELDS.COMPANY' | translate"
                    formControlName="businessName"
                    placeholder="{{'AUTH.PLACEHOLDERS.INSERT' | translate}}"
                  >
                  </literax-form-field>
                </div>
              </div>
              <div nz-row nzGutter="16">
                <div nz-col [nzXs]="10" [nzSm]="10" [nzMd]="10" [nzLg]="10">
                  <literax-form-field
                    [label]="'AUTH.FIELDS.EMAIL' | translate"
                    formControlName="email"
                    placeholder="{{'AUTH.PLACEHOLDERS.EMAIL' | translate}}"
                  >
                  </literax-form-field>
                </div>
                <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6" [nzLg]="6">
                  <nz-form-item>
                    <nz-form-label nzNoColon="true" nzFor="phone" nzRequired>{{
                      'AUTH.FIELDS.MOBILE_PHONE' | translate
                    }}</nz-form-label>
                    <nz-input-group nzAddOnBefore="+52">
                      <input
                        type="text"
                        nz-input
                        placeholder="{{'AUTH.PLACEHOLDERS.PHONE' | translate}}"
                        formControlName="phone"
                      />
                    </nz-input-group>
                  </nz-form-item>
                </div>
                <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6" [nzLg]="6">
                  <nz-form-item>
                    <nz-form-label nzNoColon="true" nzFor="phone" nzRequired>{{
                      'AUTH.FIELDS.LANDLINE_PHONE' | translate
                    }}</nz-form-label>
                    <nz-input-group nzAddOnBefore="+52">
                      <input
                        type="text"
                        nz-input
                        placeholder="{{'AUTH.PLACEHOLDERS.PHONE' | translate}}"
                        formControlName="landlinePhone"
                      />
                    </nz-input-group>
                  </nz-form-item>
                </div>
              </div>
              <div nz-row nzGutter="16">
                <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6" [nzLg]="6">
                  <literax-form-field
                    [required]="true"
                    [type]="'password'"
                    [label]="'AUTH.FIELDS.PASSWORD' | translate"
                    [hint]="'Descripción del la contraseña' | translate"
                    formControlName="password"
                    placeholder="{{'AUTH.PLACEHOLDERS.PASSWORD' | translate}}"
                  >
                  </literax-form-field>
                </div>
                <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6" [nzLg]="6">
                  <literax-form-field
                    [required]="true"
                    [type]="'password'"
                    [label]="
                      'AUTH.FIELDS.PASSWORD_CONFIRMATION_ALT' | translate
                    "
                    placeholder="{{'AUTH.PLACEHOLDERS.PASSWORD' | translate}}"
                    formControlName="confirmPassword"
                  >
                  </literax-form-field>
                  <span
                    class="literax-form-field-error"
                    *ngIf="formEmailConfirmation.get('confirmPassword').touched && formEmailConfirmation.errors?.['notMatched']"
                  >
                    <i class="fa fa-times-circle"> </i>
                    {{ 'LTX-COMPONENTS.ERRORS.NOTMATCHED' | translate }}
                  </span>
                </div>
              </div>
              <p class="required-indications">
                {{
                  'AUTH.STEP1_TEXTS.REQUIRED_INDICATIONS_1' | translate
                }}(<span class="required-red">*</span>){{
                  'AUTH.STEP1_TEXTS.REQUIRED_INDICATIONS_2' | translate
                }}
              </p>
            </form>
          </div>
        </section>
        <section *ngIf="currentStep === 1">
          <div>
            <h1 class="main-title">
              {{ 'AUTH.STEP2_TEXTS.MAIN_TITLE' | translate }}
            </h1>
            <p class="directions">
              {{ 'AUTH.STEP2_TEXTS.DIRECTIONS' | translate }}
            </p>
            <form [formGroup]="formBillingData">
              <div nz-row nzGutter="16">
                <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6" [nzLg]="6">
                  <literax-form-field
                    [label]="'AUTH.FIELDS.RFC' | translate"
                    formControlName="taxId"
                  >
                  </literax-form-field>
                </div>
                <div nz-col [nzXs]="14" [nzSm]="14" [nzMd]="14" [nzLg]="14">
                  <literax-form-field
                    [label]="'AUTH.FIELDS.COMPANY' | translate"
                    formControlName="businessName"
                  >
                  </literax-form-field>
                </div>
              </div>
              <div nz-row nzGutter="16">
                <div nz-col [nzXs]="10" [nzSm]="10" [nzMd]="10" [nzLg]="10">
                  <literax-form-field
                    [required]="true"
                    [type]="'select'"
                    [label]="'AUTH.FIELDS.TAX_REGIME' | translate"
                    [placeholder]="'Selecciona' | translate"
                    [options]="[]"
                    formControlName="taxRegime"
                  >
                  </literax-form-field>
                </div>
                <div nz-col [nzXs]="10" [nzSm]="10" [nzMd]="10" [nzLg]="10">
                  <literax-form-field
                    [required]="true"
                    [type]="'select'"
                    [label]="'AUTH.FIELDS.USE_OF_CFDI' | translate"
                    [placeholder]="'Selecciona' | translate"
                    [options]="[]"
                    formControlName="cfdi"
                  >
                  </literax-form-field>
                </div>
              </div>
              <div nz-row nzGutter="16">
                <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6" [nzLg]="6">
                  <literax-form-field
                    [required]="true"
                    [type]="'select'"
                    [label]="'AUTH.FIELDS.RESIDENCE_COUNTRY' | translate"
                    [placeholder]="'Selecciona' | translate"
                    [options]="countries"
                    formControlName="country"
                  >
                  </literax-form-field>
                </div>
                <div nz-col [nzXs]="4" [nzSm]="4" [nzMd]="4" [nzLg]="4">
                  <literax-form-field
                    [required]="true"
                    [label]="'AUTH.FIELDS.POSTAL_CODE' | translate"
                    [placeholder]="'00000'"
                    formControlName="zipCode"
                  >
                  </literax-form-field>
                </div>
               <div nz-col [nzXs]="10" [nzSm]="10" [nzMd]="10" [nzLg]="10">
                  <label nz-checkbox (ngModelChange)="toggleShowAddressForm()" formControlName="addressForm">
                    {{ 'AUTH.STEP2_TEXTS.FILL_ADDRESS_CHECK' | translate }}
                  </label>
                </div>
              </div>
            <div *ngIf="showAddressForm">
                <div nz-row nzGutter="16">
                  <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6" [nzLg]="6">
                    <literax-form-field
                      [type]="'select'"
                      [label]="'AUTH.FIELDS.STATE' | translate"
                      [placeholder]="'Selecciona' | translate"
                      [options]="[]"
                      formControlName="state"
                    >
                    </literax-form-field>
                  </div>
                  <div nz-col [nzXs]="6" [nzSm]="6" [nzMd]="6" [nzLg]="6">
                    <literax-form-field
                      [type]="'select'"
                      [label]="'AUTH.FIELDS.CITY' | translate"
                      [placeholder]="'Selecciona' | translate"
                      [options]="[]"
                      formControlName="city"
                    >
                    </literax-form-field>
                  </div>
                  <div nz-col [nzXs]="8" [nzSm]="8" [nzMd]="8" [nzLg]="8">
                    <literax-form-field
                      [label]="'AUTH.FIELDS.SUBURB' | translate"
                      [placeholder]="'Selecciona' | translate"
                      formControlName="suburb"
                    >
                    </literax-form-field>
                  </div>
                </div>
                <div nz-row nzGutter="16">
                  <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="12" [nzLg]="12">
                    <literax-form-field
                      [label]="'AUTH.FIELDS.STREET' | translate"
                      [placeholder]="'Selecciona' | translate"
                      formControlName="street"
                    >
                    </literax-form-field>
                  </div>
                  <div nz-col [nzXs]="4" [nzSm]="4" [nzMd]="4" [nzLg]="4">
                    <literax-form-field
                      [label]="'AUTH.FIELDS.EXTERNAL_NUMBER' | translate"
                      [placeholder]="'Selecciona' | translate"
                      formControlName="externalNumber"
                    >
                    </literax-form-field>
                  </div>
                  <div nz-col [nzXs]="4" [nzSm]="4" [nzMd]="4" [nzLg]="4">
                    <literax-form-field
                      [label]="'AUTH.FIELDS.INTERNAL_NUMBER' | translate"
                      [placeholder]="'Selecciona' | translate"
                      formControlName="internalNumber"
                    >
                    </literax-form-field>
                  </div>
                </div>
              </div> 
              <p class="required-indications">
                {{
                  'AUTH.STEP2_TEXTS.REQUIRED_INDICATIONS_1' | translate
                }}(<span class="required-red">*</span>){{
                  'AUTH.STEP2_TEXTS.REQUIRED_INDICATIONS_2' | translate
                }}
              </p>
            </form>
          </div>
        </section>
      </nz-card>
      <nz-card class="card-footer-content">
        <div class="footer-buttons" *ngIf="currentStep === 0">
          <button nz-button nzType="default">Regresar</button>
          <button nz-button nzType="primary" (click)="onRegistrationClicked()">
            Siguiente
          </button>
        </div>
        <div class="footer-buttons" *ngIf="currentStep === 1">
          <button nz-button nzType="default" (click)="pre()">Regresar</button>
          <button nz-button nzType="primary" (click)="onRegistrationClicked()">
            Siguiente
          </button>
        </div>
      </nz-card>
    </div>
     <section id="stepper" style="display: flex; flex-direction: column;">
        <div style="display: flex; flex-direction: column;">
          <h5 class="text-center">Proceso de compra</h5>
          <nz-steps [nzCurrent]="currentStep" [nzProgressDot]="progressTemplate">
            <nz-step nzTitle="{{ 'AUTH.STEPS.STEP_1' | translate }}"></nz-step>
            <nz-step nzTitle="{{ 'AUTH.STEPS.STEP_2' | translate }}"></nz-step>
            <nz-step nzTitle="{{ 'AUTH.STEPS.STEP_3' | translate }}"></nz-step>
            <nz-step *ngIf="!isTrial" nzTitle="{{ 'AUTH.STEPS.STEP_4' | translate }}"></nz-step>
            <nz-step nzTitle="{{ 'AUTH.STEPS.STEP_5' | translate }}"></nz-step>
          </nz-steps>
          <ng-template
            #progressTemplate
            let-dot
            let-status="status"
            let-index="index"
          >
            <span
              nz-popover
              nzPopoverContent="steps {{ index }} status: {{ status }}"
              style="margin-left: -100%"
            >
              <ng-template [ngTemplateOutlet]="dot"></ng-template>
            </span>
          </ng-template>
        </div>
        <div class="steps-content">
          <div id="step0" *ngIf="currentStep === 0">
            <nz-card [nzActions]="[footer]" [nzBordered]="false">
              <form [formGroup]="formEmailConfirmation">
                <div nz-row>
                  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                    <literax-form-field
                      [required]="true"
                      [label]="'AUTH.FIELDS.EMAIL' | translate"
                      formControlName="email"
                    >
                    </literax-form-field>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                     TODO: Agregarle al hint la descripción correspondiente 
                    <literax-form-field
                      [required]="true"
                      [type]="'password'"
                      [label]="'AUTH.FIELDS.PASSWORD' | translate"
                      [hint]="'Descripción del la contraseña' | translate"
                      formControlName="password"
                    >
                    </literax-form-field>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                    <literax-form-field
                      [required]="true"
                      [type]="'password'"
                      [label]="'AUTH.FIELDS.PASSWORD_CONFIRMATION_ALT' | translate"
                      formControlName="confirmPassword"
                    >
                    </literax-form-field>
                    <span class="literax-form-field-error" *ngIf="formEmailConfirmation.get('confirmPassword').touched && formEmailConfirmation.errors?.['notMatched']">
                      <i class="fa fa-times-circle"> </i>
                      {{'LTX-COMPONENTS.ERRORS.NOTMATCHED' | translate}}
                    </span>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                    <label nz-checkbox formControlName="acceptTerms" (ngModelChange)="updateFieldTerms()">
                      {{ 'AUTH.FIELDS.ACCEPT_TERMS_1' | translate }}
                      <a href="https://www.literax.com/wp-content/uploads/2021/06/Terminos-y-condiciones.pdf" target="_blank">
                        {{'AUTH.FIELDS.ACCEPT_TERMS_2' | translate}}
                      </a>
                      {{'AUTH.FIELDS.ACCEPT_TERMS_3' | translate}}
                      <a href="https://www.literax.com/wp-content/uploads/2021/06/aviso_de_privacidad.pdf" target="_blank">
                        {{'AUTH.FIELDS.ACCEPT_TERMS_4' | translate}}
                      </a>
                    </label>
                  </div>
                </div>
              </form>
              <ng-template #footer>
                <literax-step-buttons
                  [isFirstStep]="true"
                  [nexStepButtonEnabled]="formEmailConfirmation.valid && areTermsAccepted"
                  (cancel)="onCancelClicked()"
                  (next)="sendVerificationCode()"
                ></literax-step-buttons>
              </ng-template>
            </nz-card>
          </div>
          <div id="step1" *ngIf="currentStep === 1">
            <p>{{ 'Selecciona la membresía que mejor se adapte a sus necesidades' | translate }}</p>
            <div class="membership-container">
              <div class="membership-plans">
                <h4>Membresias individuales</h4>
                <p>Persona física</p>
                <div style="display: flex; justify-content: center;">
                  <literax-membership-card
                    *ngFor="let item of [].constructor(3); index as i"
                    [title]="'15 transacciones'"
                    [price]="420"
                    [period]="'por mes'"
                    [priceColor]="'#CF1322'"
                    [periodColor]="'#A8071A'"
                    [itemsList]="[
                      '1 usuario',
                      'Firma electrónica',
                      'Firma electrónica plus',
                      'Firma digital',
                      'Secuencia de firmas',
                      'Constancia de conservación',
                      'Centro de ayuda'
                    ]"
                    [iconColor]="'red'"
                    (selectionCard)="goToPayment('individual', i)"
                  ></literax-membership-card>
                </div>
              </div>
              <div class="membership-plans">
                <h4>Membresias empresariales</h4>
                <p>
                  Persona física con actividad empresarial o Persona moral
                </p>
                <div style="display: flex; justify-content: center;">
                  <literax-membership-card
                    *ngFor="let item of [].constructor(3); index as i"
                    [title]="'250 transacciones'"
                    [price]="5350"
                    [period]="'por mes'"
                    [priceColor]="'#595959'"
                    [periodColor]="'#262626'"
                    [itemsList]="[
                      '5 usuario',
                      'Firma electrónica',
                      'Firma electrónica plus',
                      'Firma digital',
                      'Secuencia de firmas',
                      'Constancia de conservación',
                      'Centro de ayuda'
                    ]"
                    [iconColor]="'#595959'"
                    (selectionCard)="goToBilling('moral', i)"
                  ></literax-membership-card>
                </div>
              </div>
            </div>
            <p class="membership-footer">
              {{ 'Si desea conocer más planes o un esquema personalizado, dé click ' | translate }}
              <a nz-button nzType="link" (click)="openLinkNewTab('/plans')">{{ 'aquí' | translate }}</a>
            </p>
          </div>
          <div id="step2" *ngIf="currentStep === 2">
            <nz-card [nzActions]="[footer]">
              <form [formGroup]="billingForm">
                <div nz-row nzGutter="12">
                  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="8">
                    <literax-form-field
                      [required]="true"
                      [label]="'RFC' | translate"
                      [placeholder]="'AAAA010101AAA'"
                      formControlName="taxId"
                    >
                    </literax-form-field>
                  </div>
                  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="16" [nzLg]="16">
                     TODO: Agregarle al hint la descripción correspondiente
                    <literax-form-field
                      [required]="true"
                      [label]="'Razón social' | translate"
                      [hint]="'Descripción del la contraseña' | translate"
                      [placeholder]="'Ingresar'"
                      formControlName="businessName"
                    >
                    </literax-form-field>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                    <literax-form-field
                      [required]="true"
                      [type]="'select'"
                      [label]="'Régimen fiscal' | translate"
                      [placeholder]="'Selecciona' | translate"
                      [options]="[]"
                      formControlName="taxRegime"
                    >
                    </literax-form-field>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                    <literax-form-field
                      [required]="true"
                      [type]="'select'"
                      [label]="'Uso del CFDI' | translate"
                      [placeholder]="'Selecciona' | translate"
                      [options]="[]"
                      formControlName="cfdi"
                    >
                    </literax-form-field>
                  </div>
                </div>
                <div nz-row nzGutter="12">
                  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="8" [nzLg]="8">
                    <literax-form-field
                      [required]="true"
                      [type]="'select'"
                      [label]="'País' | translate"
                      [placeholder]="'Selecciona' | translate"
                      [options]="[]"
                      formControlName="country"
                    >
                    </literax-form-field>
                  </div>
                  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6" [nzLg]="6">
                    <literax-form-field
                      [required]="true"
                      [label]="'Código postal' | translate"
                      [placeholder]="'00000'"
                      formControlName="zipCode"
                    >
                    </literax-form-field>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="24" [nzLg]="24">
                    <literax-form-field
                      [type]="'text'"
                      [label]="'Dirección' | translate"
                      [placeholder]="'Ingresar'"
                      formControlName="address"
                    >
                    </literax-form-field>
                  </div>
                </div>
                <div nz-row>
                  <div nz-col [nzXs]="10" [nzSm]="10" [nzMd]="10" [nzLg]="10">
                    <nz-form-item>
                      <nz-form-label nzNoColon="true" nzFor="phone" nzRequired>{{ 'Teléfono móvil de contacto' | translate }}</nz-form-label>
                      <nz-input-group nzAddOnBefore="+52">
                        <input
                          type="text"
                          nz-input
                          placeholder="00 0000 0000"
                          formControlName="phone"
                        />
                      </nz-input-group>
                    </nz-form-item>
                  </div>
                </div>
              </form>
              <ng-template #footer>
                <literax-step-buttons
                  [nexStepButtonEnabled]="billingForm.valid"
                  (cancel)="pre()"
                  (next)="goToPayment()"
                ></literax-step-buttons>
              </ng-template>
            </nz-card>
          </div>
          <div id="step3" *ngIf="currentStep === 3 && !isTrial">
            <nz-card [nzActions]="[footer]">
              <div #paymentWidget></div>
              <ng-template #footer>
                <literax-step-buttons
                  [isFirstStep]="true"
                  [nexStepButtonEnabled]="formEmailConfirmation.valid && areTermsAccepted"
                  (cancel)="pre()"
                  (next)="onRegistrationClicked()"
                ></literax-step-buttons>
              </ng-template>
            </nz-card>
          </div>
          <div id="step4" *ngIf="currentStep === 4">
            <nz-card>
              <nz-result
                [nzIcon]="customIconTemplate"
                [nzTitle]="customTitleTemplate"
                [nzSubTitle]="customSubTitleTemplate"
              >
                <ng-template #customIconTemplate>
                  <span nz-icon nzType="check-circle" nzTheme="outline" style="color: #52C41A;"></span>
                </ng-template>
                <ng-template #customTitleTemplate>
                  <div class="result-title">
                    <h4>
                      Gracias por registrarte a Literax
                    </h4>
                    <br>
                    <h4>
                      ¡Ya puedes aprovechar todas sus ventajas!
                    </h4>
                  </div>
                </ng-template>
                <ng-template #customSubTitleTemplate>
                  <div nz-typography nzType="secondary" class="result-text">
                    Puedes iniciar sesión desde este aviso o directamente desde nuestro sitio: <a nz-button nzType="link" (click)="goToInterfirma()">
                      {{ 'www.literax.com' | translate }}
                    </a>.<br>
                    Utiliza como Usuario la dirección de correo electrónico verificada y la Contraseña que utilizaste<br>
                    en este proceso de registro.
                  </div>
                  <div nz-typography nzType="secondary" class="result-text">
                    En caso de que no recuerdes la contraseña podrás recuperarla en <a nz-button nzType="link">
                      {{ '¿Olvidaste tu contraseña?' | translate }}
                    </a><br>
                    Al acceder por primera vez, deberás leer y aceptar los Términos & Condiciones de la aplicación.<br>
                    Recibirás adicionalmente un correo electrónico con la factura de tu servicio pagado.
                  </div>
                </ng-template>
                <div nz-result-extra>
                  <button nz-button nzType="primary" (click)="goToInterfirma()">{{ 'Iniciar sesión' | translate }}</button>
                </div>
              </nz-result>
            </nz-card>
          </div>
        </div>
      </section> 
  </nz-content>
</nz-layout> -->
