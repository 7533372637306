<!-- <nz-alert *ngIf="
    !signProcessInitiated &&
    (faceMatch || documentRequest !== eDocumentRequest.NO_REQUESTED)
  " nzType="warning" [nzMessage]="'WARNING.HEADER' | translate"
  [nzDescription]="'IDENTITY_VALIDATION.ALERT.MESSAGE' | translate" nzShowIcon></nz-alert>

<form nz-form nzLayout="vertical">
  <nz-steps nzSize="small" *ngIf="
      signProcessInitiated &&
      (faceMatch || documentRequest !== eDocumentRequest.NO_REQUESTED)
    " [nzCurrent]="step">
    <nz-step *ngFor="let s of steps" [nzTitle]="s.title"></nz-step>
  </nz-steps>

  <div class="steps-content" [hidden]="
      !(
        signProcessInitiated &&
        (faceMatch || documentRequest !== eDocumentRequest.NO_REQUESTED)
      )
    ">
    <div class="step" [hidden]="!(currentStep.key === 'front')">
      <div class="rbv-adjust" #documentFront></div>
    </div>
    <div class="step" [hidden]="!(currentStep.key === 'back')">
      <div class="rbv-adjust" #documentBack></div>
    </div>
    <div class="step" [hidden]="!(currentStep.key === 'biometric')">
      <div class="rbv-adjust" #biometricVideo></div>
    </div>
    <div class="step" [hidden]="!(currentStep.key === 'form')">
      <literax-signature-form *ngIf="currentStep.key === 'form'" [signatureBodyRequest]="signatureBodyRequest"
        [documentId]="documentId" [tokenData]="tokenData" [nameDocument]="nameDocument"
        [typeSignatureSession]="typeSignatureSession" [plusSignature]="plusSignature" [isToValidate]="isToValidate"
        [makeSignature]="makeSignature" (disableSignButton)="canSign.next($event)"></literax-signature-form>
    </div>
  </div>

  <literax-signature-form *ngIf="
      !faceMatch && documentRequest === eDocumentRequest.NO_REQUESTED
    " [signatureBodyRequest]="signatureBodyRequest" [documentId]="documentId" [tokenData]="tokenData"
    [nameDocument]="nameDocument" [typeSignatureSession]="typeSignatureSession" [plusSignature]="plusSignature"
    [isToValidate]="isToValidate" [makeSignature]="makeSignature" (disableSignButton)="canSign.next($event)">
  </literax-signature-form>
</form> -->

<!-- <div class="sign-modal-buttons" *nzModalFooter>
  <button nz-button (click)="modalRef.triggerCancel()">
    {{ 'FORM.FILE_INPUT.CANCEL' | translate }}
  </button> -->
  <!-- start: sign button -->
  <!-- <button *ngIf="
      (faceMatch &&
        signProcessInitiated &&
        currentStep === steps) ||
      (!faceMatch &&
        signProcessInitiated &&
        currentStep === steps &&
        documentRequest !== eDocumentRequest.NO_REQUESTED) ||
      (!faceMatch &&
        !signProcessInitiated &&
        documentRequest === eDocumentRequest.NO_REQUESTED) ||
      currentStep.key === 'form'
    " nz-button nzType="primary" (click)="signBehaviorButton.next()"
    [disabled]="canSign | async">
    {{ 'FORM.FILE_INPUT.SIGN' | translate }}
  </button> -->
  <!-- start: start process -->
  <!-- <button nz-button nzType="default" *ngIf="
      (!signProcessInitiated && faceMatch) ||
      (!signProcessInitiated && documentRequest !== eDocumentRequest.NO_REQUESTED)
    " (click)="signProcessInitiated = true">
    {{ 'PLANS.BUTTONS.NEXT' | translate }}
  </button> -->
  <!-- start: nextStep button -->
  <!-- <button nz-button [nzType]="(nextButtonDisabled | async) ? 'primary' : 'default'"
    *ngIf="signProcessInitiated && currentStep.key !== 'form'" (click)="nextBehaviorButton.next()"
    [disabled]="nextButtonDisabled | async">
    {{ 'FORM.FILE_INPUT.NEXT' | translate }}
  </button>
</div> -->



<nz-alert *ngIf="
    !signProcessInitiated && (signRequestValidationTypeId !== null && signRequestValidationTypeId === 2)
  " nzType="warning" [nzMessage]="'WARNING.HEADER' | translate"
  [nzDescription]="'IDENTITY_VALIDATION.ALERT.MESSAGE_ONLY_OFFICIAL_DOCS' | translate" nzShowIcon></nz-alert>

  <nz-alert *ngIf="
    !signProcessInitiated && (signRequestValidationTypeId !== null && (signRequestValidationTypeId === 3 || signRequestValidationTypeId === 4))
  " nzType="warning" [nzMessage]="'WARNING.HEADER' | translate"
  [nzDescription]="'IDENTITY_VALIDATION.ALERT.MESSAGE_WITH_BIOMETRICS_ONLY_OFFICIAL_DOCS' | translate" nzShowIcon></nz-alert>

<form nz-form nzLayout="vertical">
  <nz-steps nzSize="small" *ngIf="
      signProcessInitiated && (signRequestValidationTypeId !== null && signRequestValidationTypeId !== 1)
    " [nzCurrent]="step">
    <nz-step *ngFor="let s of steps" [nzTitle]="s.title"></nz-step>
  </nz-steps>

  <div class="steps-content" [hidden]="
      !(
        signProcessInitiated && (signRequestValidationTypeId !== null &&  signRequestValidationTypeId !== 1)
      )
    ">
    <!-- <div class="step" [hidden]="!(currentStep.key === 'front')">
      <div class="rbv-adjust" #documentFront></div>
    </div>
    <div class="step" [hidden]="!(currentStep.key === 'back')">
      <div class="rbv-adjust" #documentBack></div>
    </div> -->
    <div id="step-frame-container" class="step" [hidden]="!(currentStep.key === 'biometric')" style="height: 470px;">
      <iframe class="scaled-frame" [src]="sanitizationUrl" 
			 allow="microphone; camera"></iframe>
    </div>
    <div class="step" [hidden]="!(currentStep.key === 'form')">
      <literax-signature-form *ngIf="currentStep.key === 'form'" [signatureBodyRequest]="signatureBodyRequest"
        [documentId]="documentId" [tokenData]="tokenData" [nameDocument]="nameDocument"
        [typeSignatureSession]="typeSignatureSession" [plusSignature]="plusSignature" [isToValidate]="isToValidate"
        [makeSignature]="makeSignature" (disableSignButton)="canSign.next($event)"></literax-signature-form>
    </div>
  </div>

  <literax-signature-form *ngIf="
     signRequestValidationTypeId === null || signRequestValidationTypeId === 1
    " [signatureBodyRequest]="signatureBodyRequest" [documentId]="documentId" [tokenData]="tokenData"
    [nameDocument]="nameDocument" [typeSignatureSession]="typeSignatureSession" [plusSignature]="plusSignature"
    [isToValidate]="isToValidate" [makeSignature]="makeSignature" (disableSignButton)="canSign.next($event)">
  </literax-signature-form>
</form>




<div class="sign-modal-buttons" *nzModalFooter>
  <button nz-button (click)="modalRef.triggerCancel()">
    {{ 'FORM.FILE_INPUT.CANCEL' | translate }}
  </button>
  <!-- start: sign button -->
  <button *ngIf="
      ((signRequestValidationTypeId !== null &&  signRequestValidationTypeId !== 1) &&
        signProcessInitiated &&
        currentStep === steps) ||
      ((signRequestValidationTypeId !== null &&  signRequestValidationTypeId !== 1) &&
        signProcessInitiated &&
        currentStep === steps) ||
      ((signRequestValidationTypeId === null ||  signRequestValidationTypeId === 1) &&
        !signProcessInitiated) ||
      currentStep.key === 'form'
    " nz-button nzType="primary" (click)="signBehaviorButton.next()"
    [disabled]="canSign | async">
    {{ 'FORM.FILE_INPUT.SIGN' | translate }}
  </button>
  <!-- start: start process -->
  <button nz-button nzType="default" *ngIf="
      (!signProcessInitiated && (signRequestValidationTypeId !== null &&  signRequestValidationTypeId !== 1))
    " (click)="signProcessInitiated = true">
    {{ 'PLANS.BUTTONS.NEXT' | translate }}
  </button>
  <!-- start: nextStep button -->
  <button nz-button [nzType]="(nextButtonDisabled | async) ? 'primary' : 'default'"
    *ngIf="signProcessInitiated && currentStep.key !== 'form'" (click)="nextBehaviorButton.next()"
    [disabled]="nextButtonDisabled | async">
    {{ 'FORM.FILE_INPUT.NEXT' | translate }}
  </button>
</div>