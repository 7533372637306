<div>
  <p class="form__instructions">
    {{
      'MODULES.WORKSPACE.MODALS.MESSAGES.EMAIL_VERIFICATION.FORMS.INSTRUCTIONS'
        | translate
    }}
  </p>
  <form [formGroup]="verifyCodeForm" (ngSubmit)="verifyCode()">
    <div nz-row nzJustify="space-between">
      <div nz-col nzSpan="19">
        <literax-form-field
          formControlName="verificationCode"
          [placeholder]="
            'MODULES.WORKSPACE.MODALS.MESSAGES.EMAIL_VERIFICATION.FORMS.INPUT_VERIFICATION_CODE_PLACEHOLDER'
              | translate
          "
        ></literax-form-field>
      </div>
      <div nz-col nzSpan="4">
        <button
          type="submit" 
          nz-button
          nzType="primary"
          class="button--verify"
          [disabled]="!verifyCodeForm.valid"
        >
          {{
            'MODULES.WORKSPACE.MODALS.MESSAGES.EMAIL_VERIFICATION.FORMS.VERIFY_BUTTON'
              | translate
          }}
        </button>
      </div>
    </div>
  </form>
  <ng-container *nzModalFooter>
    <div class="footer__buttons--center">
      <button
        nz-button
        nzType="default"
        type="button"
        (click)="close()"
      >
        {{
          'MODULES.WORKSPACE.MODALS.MESSAGES.EMAIL_VERIFICATION.FORMS.RESEND_CODE_BUTTON' | translate
        }}
      </button>
    </div>
  </ng-container>
</div>
