/* eslint-disable @typescript-eslint/ban-types */
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { IQueryParameters } from '@literax/interfaces/query-parameters.interface';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseService<T = any> {
  constructor(private httpClient: HttpClient) {}

  /**
   * It's a function that returns an observable of type T, which is a generic type. It takes in a url,
   * a boolean, and a string. The boolean is optional and defaults to false. The string is optional and
   * defaults to null
   * @param {string} url - The url of the endpoint you want to call.
   * @param {boolean} [migr=false] - boolean = false
   * @param {string} [token] - The token to be used for the request.
   * @returns An Observable of type T
   */
  protected getById(
    url: string,
    migr: boolean = false,
    token?: string
  ): Observable<T> {
    const endpoint = migr
      ? `${environment.apiEndpointMigr}/${environment.apiVersionMigr}${url}`
      : `${environment.apiEndpoint}/${environment.apiVersion}${url}`;

    if (token) {
      return this.httpClient
        .get<T>(endpoint, {
          headers: this.createHeader(token),
        })
        .pipe(catchError(this.handleError));
    }

    return this.httpClient.get<T>(endpoint).pipe(catchError(this.handleError));
  }

  /**
   * It returns an observable of type T, which is a generic type
   * @param {string} url - The url of the endpoint you want to call.
   * @param {string} [token] - The token that is used to authenticate the user.
   * @returns Observable<T>
   */
  get(url: string, token?: string) {
    return this.httpClient
      .get<T>(
        `${environment.apiEndpointMigr}/${environment.apiVersionMigr}${url}`,
        {
          headers: this.createHeader(token),
        }
      )
      .pipe(catchError(this.handleError));
  }

   /**
   * It returns an observable of type T, which is a generic type
   * @param {string} url - The url of the endpoint you want to call.
   * @param {string} [token] - The token that is used to authenticate the user.
   * @returns Observable<T>
   */
   getConnector(url: string, token?: string) {
    return this.httpClient
      .get<T>(
        `${environment.guestApiEndpoint}${url}`,
        {
          headers: this.createHeader(token),
        }
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * It's a function that takes in a url, a body, a boolean, and an options object, and returns an
   * observable of type any
   * @param {string} url - The url of the endpoint you want to hit.
   * @param {T | FormData | object} body - T | FormData | object
   * @param {boolean} [migr=false] - boolean = false
   * @param {any} [options] - any - This is an optional parameter that can be used to pass in any
   * additional options to the httpClient.post method.
   * @returns Observable<any>
   */
  protected post(
    url: string,
    body: T | FormData | object,
    migr: boolean = false,
    options?: any
  ): Observable<any> {
    return this.httpClient
      .post<T>(
        `${migr ? environment.apiEndpointMigr : environment.apiEndpoint}/${
          migr ? environment.apiVersionMigr : environment.apiVersion
        }${url}`,
        body,
        options
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * It takes a url, a body, and options, and returns an observable of type T
   * @param {string} url - The url of the endpoint you want to hit.
   * @param {T | FormData | object} body - T | FormData | object
   * @param {any} [options] - any - this is the options object that you can pass to the httpClient.put
   * method.
   * @param {boolean} [migr=false] - boolean = false
   * @returns The return type is an Observable of type T.
   */
  protected put(
    url: string,
    body?: T | FormData | object,
    migr: boolean = false,
    options?: any
  ): Observable<any> {
    return this.httpClient
      .put<T>(
        `${migr ? environment.apiEndpointMigr : environment.apiEndpoint}/${
          migr ? environment.apiVersionMigr : environment.apiVersion
        }${url}`,
        body,
        options
      )
      .pipe(catchError(this.handleError));
  }

  /**
   * It takes a url, a body, and options, and returns an observable of type T
   * @param {string} url - The url of the endpoint you want to hit.
   * @param {T | FormData | object} body - T | FormData | object
   * @param {any} [options] - any - this is the options object that you can pass to the httpClient.put
   * method.
   * @param {boolean} [migr=false] - boolean = false
   * @returns The return type is an Observable of type T.
   */
  protected patch(
    url: string,
    body?: T | FormData | object,
    migr: boolean = false,
    options?: any
  ): Observable<any> {
    return this.httpClient
      .patch<T>(
        `${migr ? environment.apiEndpointMigr : environment.apiEndpoint}/${
          migr ? environment.apiVersionMigr : environment.apiVersion
        }${url}`,
        body,
        {
          ...options,
          headers: {
            'Content-Type': 'application/json-patch+json',
          },
        }
      )
      .pipe(catchError(this.handleError));
  }

  protected delete(
    url: string,
    body?: T | FormData | object,
    migr: boolean = false,
    options?: any
  ): Observable<any> {
    return this.httpClient
      .delete<T>(
        `${migr ? environment.apiEndpointMigr : environment.apiEndpoint}/${
          migr ? environment.apiVersionMigr : environment.apiVersion
        }${url}`,
        {
          ...options,
          headers: {
            'Content-Type': 'application/json-patch+json',
          },
          body,
        }
      )
      .pipe(catchError(this.handleError));
  }

  protected getObserveResponse<T>(props: any): Observable<HttpResponse<T>> {
    return this.httpClient.get<T>(
      this.apiUrl(props.endpoint, props.query),
      {
        observe: 'response',
      }
    ).pipe(catchError(this.handleError));
  }

  protected getExternalBlob<T>(props: any): Observable<Blob> {
    return this.httpClient.get(
      this.apiUrl(props.endpoint, props.query),
      {
        responseType: 'blob'
      }
    ).pipe(catchError(this.handleError))
  }

  /**
   * If the error is a client-side error, return the error. If the error is a server-side error, return
   * the error
   * @param {HttpErrorResponse} error - The error object that was thrown.
   * @returns The error message.
   */
  private handleError(error: HttpErrorResponse) {
    let e: {
      message?: string;
      level?: string;
      fields?: string[];
      httpStatus?: any;
    } = {};
    if ([400, 404, 422, 500].includes(error.status)) {
      e = Object.entries(error.error)
        .map(([k, m]) => [k.toLocaleLowerCase(), m])
        .reduce((a, c: string[]) => ({ ...a, [c[0]]: c[1] }), {});
    } else {
      e.message = error.error;
    }
    e.httpStatus = error.status;
    return throwError(e);
  }

  /**
   * It creates a new HttpHeaders object with the Authorization header set to the token
   * @param {string} token - The token that was returned from the login method.
   * @returns A new HttpHeaders object with the Authorization header set to the token.
   */
  protected createHeader(token: string): HttpHeaders {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return new HttpHeaders(headers);
  }

  private apiUrl(endpoint: string, query?: IQueryParameters) {
    const url = new URL(`${environment.apiEndpointMigr}/${environment.apiVersionMigr}${endpoint}`);
    const params = { ...query };
    let value: any;
    for (const key in params) {
      const property = key as keyof IQueryParameters;
      if (typeof params[property] === 'object') {
        value = params[property];
        if (key === 'sortField' && !!value) {
          url.searchParams.append(`${key}[name]`, value.name);
          url.searchParams.append(`${key}[type]`, value.type);
        }
        if (key === 'filterFields' && !!value) {
          for (const key in value) {
            // eslint-disable-next-line no-prototype-builtins
            if (value.hasOwnProperty(key)) {
              const element = value[key];
              url.searchParams.append(`filterFields[${key}][name]`, encodeURIComponent(element.name));
              url.searchParams.append(`filterFields[${key}][type]`, encodeURIComponent(element.type));
              url.searchParams.append(`filterFields[${key}][term]`, encodeURIComponent(element.term));
              url.searchParams.append(`filterFields[${key}][operator]`, encodeURIComponent(element.operator));
            }
          }
        }
      } else {
        value = params[property]?.toString();
        url.searchParams.append(key, value);
      }
    }
    return decodeURIComponent(url.toString());
  }
}
